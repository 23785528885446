import React, {Component} from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';
import {LoginForm, RegisterForm} from "./UserForms"
import logo from '../assets/logo.png';
import '../css/Header.css';
import getString from '../../Strings';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOverlay: "none"
    }
    this.closeOverlay = this.closeOverlay.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  onUserButtonClick(event, button) {
    event.stopPropagation();
    // Toggle if clicking the same one
    if (button === this.state.activeOverlay) {
      this.setState({activeOverlay: "none"})
    } else {
      this.setState({activeOverlay: button})
    }
  }

  closeOverlay() {
    this.setState({activeOverlay: "none"});
  }

  onLogout() {
    this.props.onLogout();
    this.props.history.push("/survey/");
  }

  render() {
    // Class selection functions for active button highlighting
    const getClassNameTabs = (button, props) => {
      return (button === props.location.pathname ? "Active" : "") + " HeaderButton";
    }
    const getClassNameUser = (button) => {
      return (button === this.state.activeOverlay ? "Active" : "") + " HeaderButton";
    }

    // Login/Register overlay
    let overlay = null;
    if (this.state.activeOverlay === "login") {
      overlay = (
        <div className="OverlayDiv" onClick={event => event.stopPropagation()}>
          <button className="CloseButton" onClick={this.closeOverlay}>X</button>
          <LoginForm onSuccess={this.props.onLoginSuccess} />
        </div>
      );
    } else if (this.state.activeOverlay === "register") {
      overlay = (
        <div className="OverlayDiv" onClick={event => event.stopPropagation()}>
          <button className="CloseButton" onClick={this.closeOverlay}>X</button>
          <RegisterForm onSuccess={this.props.onLoginSuccess} />
        </div>
      );
    }

    let isLoggedIn = this.props.user ? true : false;
    let userBar;
    if (isLoggedIn) {
      userBar = 
        <div className="HeaderUserBar">
          <span className="HelloMessage">{getString("user_hello") + this.props.user.username}!</span>
          <button className="HeaderButton" onClick={this.onLogout}>{getString("user_logout")}</button>
        </div>
    } else {
      userBar = 
        <div className="HeaderUserBar">
          <button 
            className={getClassNameUser("login")}
            onClick={event => this.onUserButtonClick(event, "login")}>{getString("button_login")}</button>
          <button
            className={getClassNameUser("register")}
            onClick={event => this.onUserButtonClick(event, "register")}>{getString("button_register")}</button>
          {overlay}
        </div>
    }

    return (
      <header className="AppHeader" onClick={this.closeOverlay}>
        {userBar}
        <img src={logo} className="AppLogo" alt="logo" />
        <div className="HeaderButtonBar">
          <Link to="/survey/" className={getClassNameTabs("/", this.props)}>{getString("tab_home")}</Link>
          <Link to="/survey/surveys" className={getClassNameTabs("/surveys", this.props)}>{getString("tab_surveys")}</Link>
          {isLoggedIn ? <Link to="/survey/results"
              className={getClassNameTabs("/survey/results", this.props)}>{getString("tab_results")}</Link> : null}
        </div>
      </header>
    );
  }
}

let HeaderWR = withRouter(Header);

export {HeaderWR};
