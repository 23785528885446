import React, {Component} from 'react';
import {
  Link, withRouter,
} from 'react-router-dom';

import '../css/Common.css';

import fb_btn from '../assets/common/fb_btn.png';
import insta_btn from '../assets/common/insta_btn.png';
import whats_btn from '../assets/common/whats_btn.png';
import menu_icon from '../assets/common/menu_icon.png';

import logo_small from '../assets/common/logo_small.png';

import que_text from '../assets/common/que_text.png';

import generic_ico from '../assets/common/generic_ico.png';
import phone_ico from '../assets/common/phone_ico.png';
import whats_ico from '../assets/common/whats_ico.png';
import email_ico from '../assets/common/email_ico.png';
import location_ico from '../assets/common/location_ico.png';
import copyright from '../assets/common/copyright.png';

function SocialButtonBar()  {
  return (
    <div className="SocialButtonBar">
      <a target="_blank" href="https://www.facebook.com/efectoboladenieve/">
        <img className="SocialButton" src={fb_btn} alt="Facebook Link"/></a>
      <a target="_blank" href="https://www.instagram.com/efectoboladenieve/?igshid=1im82a24izfjk">
        <img className="SocialButton" src={insta_btn} alt="Instagram Link"/></a>
      <a target="_blank" href="https://wa.me/5214442389449">
        <img className="SocialButton" src={whats_btn} alt="WhatsApp Link"/></a>
    </div>
  );
}

class BottomInfoBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    }
    this.linkTo = this.linkTo.bind(this);
  }

  linkTo(text, to) {
    return <Link to={to} onClick={this.props.hideMenu}>{text}</Link>
  }

  render() {
    return (
      <div className={"BottomInfoBar" + (!this.props.visible ? " Hidden" : "")}>
        <div>
          <table>
            <tbody>
              <tr className="BottomInfoHeader"><td></td><td><strong>Servicios</strong></td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td>{this.linkTo("Estudios de mercado", "/marketing-externo")}</td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td>{this.linkTo("Endomarketing (marketing interno)", "/endo-marketing")}</td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td>{this.linkTo("Comunicación visual", "/comunicacion")}</td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td>{this.linkTo("Estudios riesgos psicosociales", "/estudio")}</td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td>{this.linkTo("Entrenados Para", "entrenados")}</td></tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr className="BottomInfoHeader"><td></td><td><strong>Menú</strong></td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td>{this.linkTo("Home", "/")}</td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td>{this.linkTo("Conócenos", "/conocenos")}</td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td>{this.linkTo("Contacto", "/contacto")}</td></tr>
              <tr>
                <td><img src={generic_ico} alt=""/></td>
                <td onClick={this.props.showPrivacy}><span className="Clickable">Aviso de privacidad</span></td></tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr className="BottomInfoHeader"><td></td><td><strong>Contáctanos</strong></td></tr>
              <tr>
                <td><img src={phone_ico} alt=""/></td>
                <td>[444] <strong>590 1731</strong></td></tr>
              <tr>
                <td><img src={whats_ico} alt=""/></td>
                <td>[+52] <strong>4442389449</strong></td></tr>
              <tr>
                <td><img src={email_ico} alt=""/></td>
                <td><strong>claudia</strong>@boladenievemkt.com</td></tr>
              <tr>
                <td><img src={location_ico} alt=""/></td>
                <td>Querétaro 340 | FFCC | 78300 | S.L.P. MX.</td></tr>
            </tbody>
          </table>
        </div>

        <div className="Footer">
          <img src={copyright} alt=""/>
        </div>
      </div>
    );
  }
}

function NavBar(props) {
  return (
    <div className="NavBarHolder">
      <img src={menu_icon} alt="" className="MenuButton" onClick={props.toggleMenu}/>
      <div className={"NavBar" + (props.visible ? "" : " Hidden")}>
        <Link to="" className="Logo" onClick={props.hideMenu}>
          <img src={logo_small} alt="Bola de Nieve logo"/>
        </Link>
      </div>
    </div>
  );
}

function MiddleSection(props)  {
  let queImage = null;
  if (!props.hideQue) {
    queImage = <div><img src={que_text} alt=""/></div>;
  }
  return (
    <div className={"MiddleSection " + props.className}>
      {queImage}
      <div>
        <ul>
          {props.items.map((text, key) => <li key={key}>{text}</li>)}
        </ul>
      </div>
    </div>
  );
}

function PageIndexDisplay(props) {
  let dots = [];
  for (let i = 0; i < props.number; i++) {
    dots.push("●");
  }
  let className = "PageIndexDisplay " + (props.horizontal ? "Horizontal" : "Vertical");
  if (!props.horizontal && props.index === 0) {
    className += " Hidden";
  }

  return (
    <div className={className}>
      {dots.map((dot, index) => (
        <span key={index} className={props.index === index ? "Active" : ""} onClick={() => props.onDotClick(index)}>{dot}</span>
      ))}
    </div>
  );
}

function getCoords(event) {
  let x;
  let y;
  if (event.nativeEvent.touches && event.nativeEvent.touches.length > 0) {
    x = event.nativeEvent.touches[0].clientX;
    y = event.nativeEvent.touches[0].clientY;
  } else {
    x = event.nativeEvent.clientX;
    y = event.nativeEvent.clientY;
  }
  return {x, y};
}

class HorizontalScroller extends Component {
  constructor(props) {
    super(props);
    this.scrollRight = this.scrollRight.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollTo = this.scrollTo.bind(this);

    this.touchStart = this.touchStart.bind(this);
    this.touchMove = this.touchMove.bind(this);
    this.touchEnd = this.touchEnd.bind(this);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.resetScrollInterval = this.resetScrollInterval.bind(this);

    this.animationStart = 0; // milliseconds
    this.animiationDuration = 750; // milliseconds
    this.startDrag = null;
    this.scrollInterval = null;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown, false);
    this.scrollInterval = setInterval(() => {
      this.scrollRight();
    }, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown, false);
    clearInterval(this.scrollInterval);
  }

  resetScrollInterval() {
    clearInterval(this.scrollInterval);
    this.scrollInterval = setInterval(() => {
      this.scrollRight();
    }, 5000);
  }

  scrollRight() {
    const timeNow = Date.now();
    // Make sure animation is complete
    if (timeNow < this.animationStart + this.animiationDuration) {
      return;
    }
    this.animationStart = timeNow;
    this.startDrag = null;

    const nextPage = (this.props.activePage + 1) % this.props.pageList.length;
    this.props.pauseGoTo();
    this.props.setIndex(nextPage, this.props.activePage);
    this.resetScrollInterval();
  }

  scrollLeft() {
    const timeNow = Date.now();
    // Make sure animation is complete
    if (timeNow < this.animationStart + this.animiationDuration) {
      return;
    }
    this.animationStart = timeNow;
    this.startDrag = null;

    const nextPage = (this.props.activePage - 1 + this.props.pageList.length) % this.props.pageList.length;
    this.props.pauseGoTo();
    this.props.setIndex(nextPage, this.props.activePage);
    this.resetScrollInterval();
  }

  scrollTo(index) {
    const timeNow = Date.now();
    // Make sure animation is complete
    if (timeNow < this.animationStart + this.animiationDuration) {
      return;
    }
    this.animationStart = timeNow;
    this.startDrag = null;

    this.props.pauseGoTo();
    this.props.setIndex(index, this.props.activePage);
    this.resetScrollInterval();
  }

  touchStart(event) {
    // Make sure animation is complete
    if (Date.now() < this.animationStart + this.animiationDuration) {
      return;
    }

    let coords = getCoords(event);
    this.startDrag = {
      x: coords.x,
      y: coords.y,
    };
  }

  touchMove(event) {
    // Make sure animation is complete
    if (Date.now() < this.animationStart + this.animiationDuration) {
      return;
    }

    if (this.startDrag) {
      let coords = getCoords(event);
      let delta = {
        x: coords.x - this.startDrag.x,
        y: coords.y - this.startDrag.y,
      };
      if (delta.x < -100) {
        this.scrollRight();
      } else if (delta.x > 100) {
        this.scrollLeft();
      }
    }
  }

  touchEnd(/* event */) {
    // Make sure animation is complete
    if (Date.now() < this.animationStart + this.animiationDuration) {
      return;
    }

    this.startDrag = null;
  }

  handleKeyDown(event) {
    if (event.key === "ArrowLeft") {
      this.scrollLeft();
    }

    if (event.key === "ArrowRight") {
      this.scrollRight();
    }
  }

  render() {
    function getClassForIndex(index, props, pageList) {
      let className = "PageDivScroller";

      // Only animate the current and last pages; allows moving all other pages silently
      // in the background at key steps
      if (index !== props.activePage && index !== props.lastPage) {
        className += " NoAnimation";
      }

      // Normal case - we just moved to a page greater than 0
      if (props.activePage !== 0) {
        // When we reach the last page, silently hide the 0th page to the right
        // so on next transition it slides in from that direction
        if (props.activePage === pageList.length - 1 && index === 0) {
          className += " HiddenRight";
        } else {
          if (index < props.activePage) {
            className += " HiddenLeft";
          } else if (index > props.activePage) {
            className += " HiddenRight";
          }
        }
      } else { // Looped back around case
        if (index === pageList.length - 1) {
          className += " HiddenLeft"
        } else {
          if (index < props.activePage) {
            className += " HiddenLeft";
          } else if (index > props.activePage) {
            className += " HiddenRight";
          }
        }
      }
      return className;
    }

    let pageList = this.props.pageList;

    return (
      <div className="viewPort"
        onTouchStart={this.touchStart}
        onTouchMove={this.touchMove}
        onTouchEnd={this.touchEnd}
        onMouseDown={this.touchStart}
        onMouseMove={this.touchMove}
        onMouseUp={this.touchEnd}>
        <PageIndexDisplay number={pageList.length} index={this.props.activePage} onDotClick={this.scrollTo} horizontal/>
        {
          pageList.map((page, index) => (
            <div key={index} className={getClassForIndex(index, this.props, pageList)}>
              {page}
            </div>
          ))
        }
      </div>
    );

  }
}

function PrivacyDialog(props) {
  return (
    <div className="PrivacyDialog">
      <h2>Aviso de Privacidad</h2>
      <div className="PrivacyText">
        <p><b>Claudia Sarreón Narváez</b> con nombre comercial <b>Bola de Nieve (el "Responsable")</b>, con domicilio en calle Querétaro #340, Colonia Ferrocarrilera, C.P.78300 San Luis Potosí, S.L.P. en cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su respectivo Reglamento y demás disposiciones aplicables (en lo sucesivo la "Ley"), le informa que es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección, a través de la implementación de medidas de seguridad técnicas, administrativas y físicas que permitan protegerlos contra cualquier daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado por usted como Titular, por lo que pone a su disposición el presente Aviso de Privacidad:</p>
        <h4>INFORMACIÓN A RECABAR:</h4>
        <p>Los datos que usted como Titular nos proporcione serán tratados bajo los principios establecidos en la Ley (licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad).</p>
        <p>Hacemos de su conocimiento que, El Responsable requiere obtener y tratar los siguientes datos personales para llevar a cabo las finalidades descritas en el presente "Aviso de Privacidad":</p>
        <ul>
          <li>Nombre completo;</li>
          <li>Dirección (calle, colonia, código postal, delegación o municipio y estado)</li>
          <li>Características de la vivienda;</li>
          <li>Teléfonos fijo, móvil, de trabajo o particular;</li>
          <li>Correo electrónico;</li>
          <li>Redes sociales;</li>
          <li>Edad;</li>
          <li>Género;</li>
          <li>Estado civil;</li>
          <li>Grado máximo de estudios, universidad o colegios a los que acude o acudió;</li>
          <li>Historia o preferencias de consumo en toda categoría;</li>
        </ul>
        <h4>DATOS PATRIMONIALES</h4>
        <p>Para efectos de realizar las actividades del Objeto del “El Responsable” se solicitan datos financieros que son tratados por el mismo. Los datos financieros solicitados son:</p>
        <ul>
          <li>Información para determinar su nivel socioeconómico;</li>
          <li>Información relacionada con las características de su vivienda;</li>
        </ul>
        <p>La recolección y obtención de los datos personales podrá llevarse a cabo cuando el Titular visite nuestras instalaciones en el domicilio antes mencionado, cuando se encuentra en diferentes centros de afluencia, cuando sea contactado por nuestros encuestadores, entrevistadores o reclutadores en su domicilio particular o laboral, cuando el Responsable se comunique de forma telefónica o por la red mundial denominada Internet con el Titular, así como mediante sesiones de grupo, entrevistas, etnografías, videograbaciones o bien mediante la utilización del sitio web del Responsable ya sea por encuestas directas o a través del uso de cookies al navegar por nuestra página de Internet. También podremos recolectar su información mediante fuentes de acceso público o fuentes lícitas disponibles en el mercado con las cuales usted haya otorgado su consentimiento para compartir su información personal.</p>
        <h4>FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES:</h4>
        <p>Los Datos Personales en posesión de El Responsable serán utilizados para:</p>
        <ul>
          <li>Realizar estudios de mercado o de opinión pública y comunicación;</li>
          <li>Estudio y elaboración de registros y estadísticas, así como el análisis de información sobre los mismos.</li>
          <li>Realizar estudios sobre los datos demográficos, intereses y comportamiento de los servicios y/o productos de sus clientes, consumidores, proveedores, y de aquellos terceros con los que trate;</li>
          <li>Llevar a cabo videograbación de la realización de los estudios, de las sesiones de grupos y/o entrevistas.</li>
          <li>Elaboración de reportes, análisis de mercados, análisis de casos de mercado, análisis de caso de personas o de empresas, análisis socio-psicológicos, análisis económicos y sondeos de opinión sobre productos, bienes, servicios, personas, marcas, nombres comerciales o diferentes cuestiones de las cuales se solicitará su opinión y que serán informadas a usted de forma clara y precisa.</li>
          <li>Elaboración de reportes, análisis de mercados, análisis de caso de personas o de empresas, análisis socio-psicológicos, análisis económicos y sondeos de opinión sobre estructuras internas, procesos y ambiente laboral, de las empresas en las que es empleado siempre y cuando las características del estudio así lo requieran.</li>
          <li>Seguimiento y evaluación de la calidad de servicios o productos de nuestros clientes o contratistas.</li>
          <li>Análisis internos de servicios de nuestros clientes o contratistas.</li>
          <li>Compartir datos con terceros prestadores de servicios o proveedores del Responsable, con la finalidad de poder proporcionar los servicios del Responsable.</li>
          <li>Realizar estudios sobre hábitos de consumo.</li>
          <li>Diseño gráfico y branding</li>
          <li>Para dar cumplimiento a las obligaciones contractuales que asumamos con nuestros clientes.</li>
        </ul>
        <h4>TRANSFERENCIA DE LOS DATOS PERSONALES.</h4>
        <p>A) Bola de Nieve requiere transferir los datos personales en su posesión a terceros subcontratados o relacionados con la prestación de servicios, que tengan el carácter de proveedores del Responsable y los que estarán en todo caso sujetos a los fines y demás condiciones respecto del tratamiento de los datos personales que se establecen en este Aviso de Privacidad.</p>
        <p>B) El Responsable podrá realizar transferencias de datos personales a terceros, nacionales o extranjeros, clientes del Responsable, en la inteligencia que el tratamiento a los datos personales por parte de dichos terceros, deberá ajustarse estrictamente a lo establecido en el presente Aviso de Privacidad y únicamente con las finalidades secundarias: Observación por parte del cliente en el desarrollo del estudio. iii. • Realizar actividades de mercadeo y promoción. iv. Análisis estadísticos y de mercado. • Invitación a eventos y/o concursos.</p>
        <p>El Responsable se compromete a no transferir sus datos personales a terceros sin su consentimiento y/o este Aviso de Privacidad salvo las excepciones previstas en el artículo 37 de la LFPDPPP y su reglamento.</p>
        <h4>MEDIOS PARA EJERCER DERECHOS ARCO (ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN)</h4>
        <p>En todo momento, usted tiene derecho de acceder a los datos personales que poseamos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o solicitar su cancelación cuando considere que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al tratamiento de los mismos, mediante la solicitud respectiva en escrito libre que cumpla con los requisitos establecidos en la Ley. En caso de que requiera información sobre dicha solicitud, podrá hacerlo, enviando un correo electrónico incluyendo los elementos permitan la localización de los datos personales y atención a la solicitud a la dirección claudia@boladenievemkt.com</p>
        <p>Una vez recibida su solicitud, le comunicaremos la determinación adoptada dentro del plazo que establece la Ley y en caso de que sea procedente, se hará efectiva de acuerdo a los plazos señalados por la misma.</p>
        <h4>COOKIES EN NUESTRO SITIO WEB</h4>
        <p>Las cookies son pequeñas piezas de información que son enviadas por el sitio web a su navegador y se almacenan en el disco duro de su equipo. La información que se colecta es anónima, y puede ser compartida con terceros para mejorar su experiencia de navegación y entregar publicidad con base en sus intereses. Estos datos no incluyen ningún tipo de información de identificación personal.</p>
        <p>El usuario acepta el tratamiento y uso de Cookies de conformidad con el presente Aviso de Privacidad.</p>
        <h4>CAMBIOS AL AVISO DE PRIVACIDAD:</h4>
        <p>El presente Aviso de Privacidad podrá ser modificado en el futuro. En todo caso, cualquier modificación al mismo se hará de su conocimiento mediante la publicación del mismo en ésta misma página WEB.</p>
        <p>Si usted no manifiesta su oposición, se entiende que ha leído y acepta los términos contenidos en el presente Aviso de Privacidad.</p>
      </div>
      <button onClick={props.hidePrivacy}>Close</button>
    </div>
  );
}

// props: pageList (a list of elements with root node PageDiv)
class PageListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
      lastPage: 0,
    };
    this.scrollDown = this.scrollDown.bind(this);
    this.scrollUp = this.scrollUp.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.touchStart = this.touchStart.bind(this);
    this.touchMove = this.touchMove.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.animationStart = 0; // milliseconds
    this.animiationDuration = 750; // milliseconds
    this.startDrag = null;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown, false);
    this.unlisten = this.props.history.listen(() => {
      this.setState({
        activePage: 0,
        lastPage: 0,
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown, false);
    this.unlisten();
  }

  scrollDown() {
    // Scrolling down, activePage goes up (note it's length and not length - 1 because we insert a page)
    if (this.state.activePage < this.props.pageList.length) {
      const timeNow = Date.now();
      // Make sure animation is complete
      if (timeNow < this.animationStart + this.animiationDuration) {
        return;
      }
      this.animationStart = timeNow;

      this.startDrag = null;
      this.setState({
        activePage: this.state.activePage + 1,
        lastPage: this.state.activePage,
      });
      document.body.style = "overscroll-behavior-y: none";
    }
  }

  scrollUp() {
    // Scrolling down, activePage goes down
    if (this.state.activePage > 0) {
      const timeNow = Date.now();
      // Make sure animation is complete
      if (timeNow < this.animationStart + this.animiationDuration) {
        return;
      }
      this.animationStart = timeNow;

      this.startDrag = null;
      if (this.state.activePage - 1 === 0) {
        document.body.style = "overscroll-behavior-y: initial";
      }
      this.setState({
        activePage: this.state.activePage - 1,
        lastPage: this.state.activePage,
      });
    }
  }

  // Only used on vertical style for scrolling
  handleWheel(event) {
    // Make sure animation is complete
    if (Date.now() < this.animationStart + this.animiationDuration) {
      return;
    }

    if (event.deltaY > 0) {
      this.scrollDown();
    } else if (event.deltaY < 0) {
      this.scrollUp();
    }
  }

  touchStart(event) {
    // Make sure animation is complete
    if (Date.now() < this.animationStart + this.animiationDuration) {
      return;
    }

    let coords = getCoords(event);
    this.startDrag = {
      x: coords.x,
      y: coords.y,
    };
  }

  touchMove(event) {
    // Make sure animation is complete
    if (Date.now() < this.animationStart + this.animiationDuration) {
      return;
    }

    if (this.startDrag) {
      let coords = getCoords(event);
      let delta = {
        x: coords.x - this.startDrag.x,
        y: coords.y - this.startDrag.y,
      };
      if (delta.y < -100) {
        this.scrollDown();
      } else if (delta.y > 100) {
        this.scrollUp();
      }
    }
  }

  touchEnd(/* event */) {
    // Make sure animation is complete
    if (Date.now() < this.animationStart + this.animiationDuration) {
      return;
    }

    this.startDrag = null;
  }

  handleKeyDown(event) {
    if (event.key === "ArrowUp") {
      this.scrollUp();
    }

    if (event.key === "ArrowDown") {
      this.scrollDown();
    }
  }

  render() {
    if (this.props.getScroll() === true) {
      setTimeout(() => {
        this.scrollDown();
      }, 10);
    }

    if (this.props.header) {
      // Handle cases where narrow -> wide layouts decreases # of pages
      // Note: we're adding 1 to pageList.length because header is here but not counted
      if (this.state.activePage > this.props.pageList.length) {
        this.state.activePage = this.props.pageList.length;
      }
    } else {
      if (this.state.activePage > this.props.pageList.length - 1) {
        this.state.activePage = this.props.pageList.length - 1;
      }
    }

    function getClassForIndex(index, state) {
      let className = "PageDivScroller";

      // Only animate the current and last pages; allows moving all other pages silently
      // in the background at key steps
      if (index !== state.activePage && index !== state.lastPage) {
        className += " NoAnimation";
      }

      if (index < state.activePage) {
        className += " HiddenUp";
      } else if (index > state.activePage) {
        className += " HiddenDown";
      }
      return className;
    }

    let solicitaLink = null;
    const showLink = !this.props.horizontal && this.state.activePage > 0;
    const linkClass = "SolicitaLink" + (showLink ? "" : " Hidden");
    if (this.props.showLink) {
      solicitaLink = <Link className={linkClass} to="/contacto">SOLICITA TU SERVICIO A LA MEDIDA</Link>;
    }

    let pageList = this.props.pageList;
    if (this.props.header) {
      pageList = [this.props.header].concat(this.props.pageList);
    }
    return (
      <div className="viewPort" onWheel={this.handleWheel}
           onTouchStart={this.touchStart}
           onTouchMove={this.touchMove}
           onTouchEnd={this.touchEnd}
           onMouseDown={this.touchStart}
           onMouseMove={this.touchMove}
           onMouseUp={this.touchEnd} >
        <PageIndexDisplay number={pageList.length} index={this.state.activePage} onDotClick={() => {}}/>
        {solicitaLink}
        {
          pageList.map((page, index) => (
            <div key={index} className={getClassForIndex(index, this.state)}>
              {page}
            </div>
          ))
        }
      </div>
    );
  }
}
let PageListContainerWR = withRouter(PageListContainer);

export {
  HorizontalScroller,
  MiddleSection,
  NavBar,
  BottomInfoBar,
  PageListContainerWR,
  SocialButtonBar,
  PrivacyDialog,
};
