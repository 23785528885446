import React, {Component} from 'react';
import '../css/Utils.css';
import getString from '../../Strings';

let host = "https://boladenievemkt.com/";
if (process.env.NODE_ENV !== 'production') {
  host = "http://localhost:3000/"
}

// GITPOD - the port is prepended to the workspace URL
if (process.env.REACT_APP_GITPOD_URL) {
    let a = process.env.REACT_APP_GITPOD_URL;
    host = [a.slice(0, 8), "3000-", a.slice(8)].join('') + "/";
}

function apiUrl(api) {
  return new URL(host + api);
}

function postApi(api, data) {
  return fetchApi(api, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
})
}

function getApi(api, params) {
  return fetchApi(api, {method: "get"}, params);
}

function fetchApi(api, config, params) {
  var url = new URL(host + api);
  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  }

  config.credentials = 'include';
  return fetch(url, config);
}

class StateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main: {},
      submitting: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  async onSubmit(event) {
    event.preventDefault();
    this.setState({submitting: true});
    let body = JSON.parse(JSON.stringify(this.state["main"]));

    for (const key in this.props.extras) {
      body[key] = this.props.extras[key];
    }

    let res = await postApi(this.props.api, body);
    let data = await res.json();

    this.setState({
      formError: data.error,
      submitting: false,
    });

    if (data.success) {
      if (this.props.onSuccess) {
        this.props.onSuccess(data);
      }
      if (this.props.clearOnSuccess) {
        this.setState({main: {}});
      }
    }
  }

  updateState(name, stateChange) {
    let newState = {...this.state[name]}
    // Manually apply changes to sub-object
    for (const key in stateChange) {
      newState[key] = stateChange[key];
    }
    let update = {}
    update[name] = newState;
    this.setState(update);
  }

  render() {
    let errors = {};
    if (this.props.validate) {
      errors = this.props.validate(this.state["main"]);
    }

    let submit =
      <input
        type="submit"
        className={"SubmitButton" + (this.props.submitClass ? " " + this.props.submitClass : "")}
        disabled={this.state.submitting || Object.keys(errors).length} value={this.props.submitText || getString("button_submit")} />

    if (this.props.requireText) {
      submit =
        <div>
          {this.props.requireText}
          {submit}
        </div>
    }

    return (
      <form className={"Form" + (this.props.className ? " " + this.props.className : "")} onSubmit={this.onSubmit}>
        <span className="FormError">{this.state.formError}</span>
        <StateFormFragment name="main"
          updateState={this.updateState}
          fields={this.props.fields}
          values={this.state["main"]}
          errors={{}}/>
        {submit}
      </form>
    );
  }
}

/**
 * Generic state-backed form. Props required:
 * {
 *   fields: [],
 *   onSubmit: function(state){}
 *   validate: function(state){ return {key: "", error: ""} }
 *   extras: {}
 *   inputClass: ""
 *   labelClass: ""
 *   submitClass: ""
 *   selectOptions: {key: "Name", ...}
 * }
 *
 * Where fields is an array of objects that define the fields as follows:
 * {
 *   key: "key_string",
 *   name: "Visible Name",
 *   type: "password|text|select|textarea|etc."
 *   props: {any other props on the components}
 * }
 * And extras is an object to send along with the form state
 */
class StateFormFragment extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event, field) {
    let stateChange = {};
    stateChange[field] = event.target.value;
    this.props.updateState(this.props.name, stateChange);
  }

  render() {
    let fields = [];
    for (let idx in this.props.fields) {
      let field = this.props.fields[idx];
      let input = null;
      if (field.type === "select") {
        let options = [
          <option key={"unique_key"}>{getString("form_select_default")}</option>
        ];
        for (const key in field.selectOptions) {
          options.push(<option value={key} key={key}>{field.selectOptions[key]}</option>);
        }
        input = (
          <select
            onChange={event => {this.handleInputChange(event, field.key)}}
            value={this.props.values[field.key] || ""}
            {...field.props}>
            {options}
          </select>
        );
      } else if (field.type === "textarea") {
        input = (
          <textarea
              className={"RowInput " + this.props.inputClass}
              value={this.props.values[field.key] || ""}
              onChange={event => {this.handleInputChange(event, field.key)}}
              {...field.props}/>
        );
      }else {
        input = (
          <input
            className={"RowInput " + this.props.inputClass}
            type={field.type}
            value={this.props.values[field.key] || ""}
            onChange={event => {this.handleInputChange(event, field.key)}}
            {...field.props} />
        );
      }

      // Label
      fields.push(
        <div className="FormField" key={idx}>
          <div className="FieldRow">
            <span
              className={"RowLabel " + this.props.labelClass}>{field.name}</span>
            {input}
          </div>
          <span className="FieldError">{this.props.errors[field.key]}</span>
        </div>
      );
    }
    return (
      <div>
        {fields}
      </div>
    );
  }
}

export {apiUrl, postApi, getApi, StateForm, StateFormFragment};
