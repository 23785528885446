import React from 'react';

import '../css/ExternoPage.css';

import {MiddleSection} from "./Common";

import header from '../assets/externo/header.jpg';
import header_text from '../assets/externo/header_text.png';
import como_text from '../assets/common/como_text.png';
import contar_text from '../assets/externo/contar_text.png';

// TODO update these when I get the new images
import top_left from '../assets/externo/top_left.jpg';
import top_right from '../assets/externo/top_right.jpg';
import bottom_left from '../assets/externo/bottom_left.jpg';
import bottom_right from '../assets/externo/bottom_right.jpg';

function ComoTile(props) {
  return (
    <div className="ComoTile">
      <img src={props.image} alt=""/>
      <div>
        {props.text}
      </div>
    </div>
  );
}

function ComoTileGroupHalf1()  {
  let items = [
    <span>ESTUDIO DE MEDICIÓN DE IMPACTO<br/>DE CAMPAÑAS PUBLICITARIAS</span>,
    <span>ESTUDIO DE IDENTIFICACIÓN DEL POSICIONAMIENTO<br/>DE MARCAS EN EL MERCADO</span>,
  ];

  let images = [
    top_left,
    top_right,
  ];

  return (
    <div className="ComoTileGroupHalf">
      <ComoTile text={items[0]} image={images[0]}/>
      <ComoTile text={items[1]} image={images[1]}/>
    </div>
  );
}

function ComoTileGroupHalf2()  {
  let items = [
    <span>ESTUDIO DE FACTIBILIDAD<br/>DE LANZAMIENTO DE PRODUCTO</span>,
    <span>ESTUDIO DE FACTIBILIDAD<br/>DE APERTURA DE TIENDA</span>,
  ];

  let images = [
    bottom_left,
    bottom_right,
  ];

  return (
    <div className="ComoTileGroupHalf">
      <ComoTile text={items[0]} image={images[0]}/>
      <ComoTile text={items[1]} image={images[1]}/>
    </div>
  );
}

function ComoTileGroupFull()  {
  let items = [
    <span>ESTUDIO DE MEDICIÓN DE IMPACTO<br/>DE CAMPAÑAS PUBLICITARIAS</span>,
    <span>ESTUDIO DE IDENTIFICACIÓN DEL POSICIONAMIENTO<br/>DE MARCAS EN EL MERCADO</span>,
    <span>ESTUDIO DE FACTIBILIDAD<br/>DE LANZAMIENTO DE PRODUCTO</span>,
    <span>ESTUDIO DE FACTIBILIDAD<br/>DE APERTURA DE TIENDA</span>,
  ];

  let images = [
    top_left,
    top_right,
    bottom_left,
    bottom_right,
  ];

  return (
    <div className="ComoTileGroup">
      <ComoTile text={items[0]} image={images[0]}/>
      <ComoTile text={items[1]} image={images[1]}/>
      <br/>
      <ComoTile text={items[2]} image={images[2]}/>
      <ComoTile text={items[3]} image={images[3]}/>
    </div>
  );
}

function ExternoHeader(props) {
  return (
    <div className="ExternoPage PageDiv" onClick={() => props.goTo("/marketing-externo")}>
      <img className="HeaderImg" src={header} alt=""/>
      <img className="HeaderTextImg" src={header_text} alt=""/>
    </div>
  );
}

function Page1() {
  let items = [
    "Segmentar tu mercado; conocer perfiles, hábitos.",
    "Interpretar datos cuantitativos y cualitativos con un análisis competitivo",
    "Controlar los riesgos que implica el lanzamiento de un nuevo producto o establecimiento",
    "Conocer donde se encuentra tu producto o servicio en relación a otros similares en el mercado",
    "Saber la posición de tu marca en la mente del consumidor",
    "Verificar si el mensaje de tu campaña se está transmitiendo así como obtener una retroalimentación para aumentar su efectividad",
    "Obtener una retroalimentación de tus clientes para lograr y mantener el éxito de tu negocio",
  ];

  return (
    <div className="ExternoPage PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="InfoTextImg" src={contar_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <MiddleSection items={items} className="MiddleSectionExterno"/>
        </div>
      </div>
    </div>
  );
}

function Page1p1() {
  let items = [
    "Segmentar tu mercado; conocer perfiles, hábitos.",
    "Interpretar datos cuantitativos y cualitativos con un análisis competitivo",
    "Controlar los riesgos que implica el lanzamiento de un nuevo producto o establecimiento",
  ];

  return (
    <div className="ExternoPage PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="InfoTextImg" src={contar_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <MiddleSection items={items} className="MiddleSectionExterno"/>
        </div>
      </div>
    </div>
  );
}

function Page1p2() {
  let items = [
    "Conocer donde se encuentra tu producto o servicio en relación a otros similares en el mercado",
    "Saber la posición de tu marca en la mente del consumidor",
    "Verificar si el mensaje de tu campaña se está transmitiendo así como obtener una retroalimentación para aumentar su efectividad",
    "Obtener una retroalimentación de tus clientes para lograr y mantener el éxito de tu negocio",
  ];

  return (
    <div className="ExternoPage PageDiv">
        <div className="CenterVert">
          <MiddleSection hideQue items={items} className="MiddleSectionExterno"/>
        </div>
    </div>
  );
}

function Page2() {
  return (
    <div className="PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="ComoTileGroupHolder">
          <ComoTileGroupFull />
        </div>
      </div>
    </div>
  );
}

function Page2p1() {
  return (
    <div className="PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="ComoTileGroupHolder">
          <ComoTileGroupHalf1 />
        </div>
      </div>
    </div>
  );
}

function Page2p2() {
  return (
    <div className="PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="ComoTileGroupHolder">
          <ComoTileGroupHalf2 />
        </div>
      </div>
    </div>
  );
}

const ExternoPageList = [
  <Page1 key="1" />,
  <Page2 key="2" />,
];

const ExternoPageListPortrait = [
  <Page1 key="1" />,
  <Page2p1 key="2" />,
  <Page2p2 key="3" />,
];

const ExternoPageListPortraitSmaller = [
  <Page1p1 key="1" />,
  <Page1p2 key="2" />,
  <Page2p1 key="3" />,
  <Page2p2 key="4" />,
];

export {
  ExternoHeader,
  ExternoPageList,
  ExternoPageListPortrait,
  ExternoPageListPortraitSmaller,
}
