import React, {Component} from 'react';
import {HeaderWR} from './Header';
import {SurveyPageWR} from './Surveys';
import {ResultsPageWithRouter} from './Results';
import getString from '../../Strings';

import {getApi} from './Utils';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Cookies from 'universal-cookie';
import '../css/SurveyApp.css';

const cookies = new Cookies();

function SurveyHomePage() {
  return (
    <div>
      <h1>
        {getString("home_welcome")}
      </h1>
      <p>{getString("home_instructions_0")}</p>
      <p>{getString("home_instructions_1")}</p>
    </div>
  );
}

class SurveyApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUser: null,
    };
    this.onLogout = this.onLogout.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.localLogout = this.localLogout.bind(this);
  }

  async componentDidMount() {
    let res = await getApi("usersApi/getSessionUser");
    let data = await res.json();
    if (data.user) {
      this.setState({activeUser: data.user});
    }
  }

  async onLogin() {
    let res = await getApi("usersApi/getSessionUser");
    let data = await res.json();
    this.setState({activeUser: data.user});
  }

  async onLogout() {
    let res = await getApi("usersApi/logout");
    let data = await res.json();
    if (data.success) {
      cookies.remove('connect.sid');
      this.setState({activeUser: null})
    } else {
      console.log(data.error);
    }
  }

  localLogout() {
    cookies.remove('connect.sid');
    this.setState({activeUser: null})
  }

  render() {
    return (
      <Router>
        <div className="App">
          <HeaderWR
            user={this.state.activeUser}
            onLoginSuccess={this.onLogin}
            onLogout={this.onLogout} />
          <div className="Page">
            <Switch>
              <Route exact path="/survey">
                <SurveyHomePage />
              </Route>
              <Route path="/survey/surveys">
                <SurveyPageWR user={this.state.activeUser} />
              </Route>
              <Route path="/survey/results">
                <ResultsPageWithRouter localLogout={this.localLogout}/>
              </Route>
            </Switch>
          </div>
        </div>        
      </Router>
    );
  }
}

export default SurveyApp;