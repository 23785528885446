import React, {Component} from 'react';
import {StateForm} from './Utils';
import getString from '../../Strings';

function LoginForm(props) {
  let fields = [
    {key: "username", name: getString("user_form_username"), type: "text"},
    {key: "password", name: getString("user_form_password"), type: "password"},
  ];
  return (
    <StateForm
      fields={fields}
      api={"usersApi/login"}
      onSuccess={props.onSuccess} />
  );
}

class RegisterForm extends Component {
  validate(state) {
    let errors = {};
    if (state.password !== state.passwordConfirm) {
      errors.passwordConfirm = getString("user_form_match_error");
    }
    return errors;
  }

  render() {
    let fields = [
      {key: "username", name: getString("user_form_username"), type: "text"},
      {key: "password", name: getString("user_form_password"), type: "password"},
      {key: "passwordConfirm", name: getString("user_form_password_confirm"), type: "password"},
    ];
    return (
      <StateForm 
        fields={fields}
        validate={this.validate}
        api={"usersApi/register"}
        onSuccess={this.props.onSuccess} />
    );
  }
}

export {LoginForm, RegisterForm};
