import React, {Component} from 'react';

import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';

import {
  HorizontalScroller,
  NavBar,
  PageListContainerWR,
  PrivacyDialog,
  BottomInfoBar,
} from "./Common"

import {HomePageList, HomeHeader, HomePageListNarrow} from "./HomePage";
import {ExternoPageList, ExternoHeader, ExternoPageListPortrait, ExternoPageListPortraitSmaller} from "./ExternoPage";
import {EndoPageList, EndoHeader, EndoPageListPortait} from "./EndoPage";
import {EstudioPageList, EstudioHeader, EstudioPageListPortrait} from "./EstudioPage";
import {ComunicacionPageList, ComunicacionHeader, ComunicacionPageListPortrait} from "./ComunicacionPage";
import {ConocenosPageList, ConocenosHeader, ConocenosPageListNarrow, ConocenosPageListPortrait} from "./ConocenosPage";
import {EntrenadosPageList, EntrenadosHeader, EntrenadosPageListPortait} from "./EntrenadosPage";
import {ContactoPageList, ContactoHeader, ContactoPageListPortait} from './ContactoPage';
import {SocialButtonBar} from "./Common";

import home_button from '../assets/common/home_button.png';
import '../css/SiteApp.css';

const pathToIndex = {
  "/" : 0,
  "/aviso" : 0,
  "/marketing-externo" : 1,
  "/endo-marketing" : 2,
  "/estudio" : 3,
  "/comunicacion" : 4,
  "/conocenos" : 5,
  "/entrenados" : 6,
  "/contacto" : 7,
}

let lastPage = -1;
class HeaderScrollList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: pathToIndex[props.location.pathname],
    }
    this.pauseGoTo = this.pauseGoTo.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location) => {
      this.props.onRouteChange();
      this.setState({activeIndex: pathToIndex[location.pathname]});
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  pauseGoTo() {
    this.disableGoTo = true;
    setTimeout(() => {
      this.disableGoTo = false;
    }, 750);
  }

  goTo(link) {
    if (this.props.location.pathname === link || this.disableGoTo) {
      return;
    }
    this.props.history.push(link);
    this.props.scrollDown();
  }

  render() {
    let items = [
      <HomeHeader goTo={this.goTo} key="0"/>,
      <ExternoHeader goTo={this.goTo} key="1"/>,
      <EndoHeader goTo={this.goTo} key="2"/>,
      <EstudioHeader goTo={this.goTo} key="3"/>,
      <ComunicacionHeader goTo={this.goTo} key="4"/>,
      <ConocenosHeader goTo={this.goTo} key="5"/>,
      <EntrenadosHeader goTo={this.goTo} key="6"/>,
      <ContactoHeader goTo={this.goTo} key="7"/>,
    ];

    if (this.props.location.pathname === "/") {
      return (
        <HorizontalScroller
          pageList={items}
          activePage={this.state.activeIndex}
          lastPage={lastPage}
          pauseGoTo={this.pauseGoTo}
          setIndex={(index, last) => {
            lastPage = last;
            this.props.allowAnimations();
            this.setState({activeIndex: index});
          }}/>
      );
    } else {
      return items[this.state.activeIndex];
    }
  }
}
let HeaderScrollListWR = withRouter(HeaderScrollList);

class SiteApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableAnimations: false,
      showMenu: false,
      showPrivacy: false,
    }
    this.allowAnimations = false;
    this.scrollDown = false;

    this.toggleMenu = this.toggleMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.getScroll = this.getScroll.bind(this);

    this.showPrivacy = this.showPrivacy.bind(this);
  }

  onWindowResize() {
    this.setState({disableAnimations: true});
    setTimeout(() => {
      this.setState({disableAnimations: false});
    }, 10)
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize.bind(this));
    if (this.props.location.pathname === "/aviso") {
      this.showPrivacy();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize.bind(this));
  }

  toggleMenu() {
    this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu() {
    this.setState({
      showMenu: false,
      showPrivacy: false,
    });
  }

  showPrivacy() {
    this.setState({
      showMenu: false,
      showPrivacy: true,
    });
  }

  getScroll() {
    if (this.scrollDown) {
      this.scrollDown = false;
      return true;
    }
    return false;
  }

  render() {
    let header = <HeaderScrollListWR
        allowAnimations={() => this.allowAnimations = true}
        onRouteChange={() => {
          if (!this.allowAnimations) {
            this.setState({disableAnimations: true});
            setTimeout(() => {
              this.setState({disableAnimations: false});
            }, 10)
          }
          this.allowAnimations = false;
        }}
        scrollDown={() => {
          this.scrollDown = true;
        }}/>;

    let homePageList = HomePageList;
    if (window.innerWidth < 1180 || window.innerHeight < 580) {
      homePageList = HomePageListNarrow;
    }

    let conocenosPageList = ConocenosPageList;
    if (window.innerWidth < 1080 || window.innerHeight < 640) {
      conocenosPageList = ConocenosPageListNarrow;
    }

    let externoPageList = ExternoPageList;
    let endoPageList = EndoPageList;
    let estudioPageList = EstudioPageList;
    let comuPageList = ComunicacionPageList;
    let contactoPageList = ContactoPageList;
    let entrenadosPageList = EntrenadosPageList;
    if (window.innerWidth < window.innerHeight) {
      externoPageList = ExternoPageListPortrait
      endoPageList = EndoPageListPortait;
      estudioPageList = EstudioPageListPortrait;
      comuPageList = ComunicacionPageListPortrait;
      conocenosPageList = ConocenosPageListPortrait;
      contactoPageList = ContactoPageListPortait;
      entrenadosPageList = EntrenadosPageListPortait;
    }
    if (window.innerWidth < 400 || window.innerHeight < 500) {
      externoPageList = ExternoPageListPortraitSmaller;
    }

    return (
      <div className={"Body NoInteract" + (this.state.disableAnimations ? " NoAnimation" : "")}>
        <SocialButtonBar />
        <Router>
          <Link to="/" className="HomeButton">
            <img src={home_button} alt=""/>
          </Link>
          <NavBar visible={this.state.showMenu} toggleMenu={this.toggleMenu} hideMenu={this.hideMenu}/>
          <BottomInfoBar visible={this.state.showMenu} hideMenu={this.hideMenu} showPrivacy={this.showPrivacy}/>
          {this.state.showPrivacy && <PrivacyDialog hidePrivacy={this.hideMenu}/>}
          <div className={"Darken" + ((this.state.showMenu || this.state.showPrivacy) ? "" : " Hidden")}
               onClick={this.hideMenu}/>
          <Switch>
            <Route exact path="/">
              <PageListContainerWR header={header} pageList={homePageList} getScroll={this.getScroll}/>
            </Route>
            <Route path="/aviso">
              <PageListContainerWR header={header} pageList={homePageList} getScroll={this.getScroll}/>
            </Route>
            <Route path="/marketing-externo">
              <PageListContainerWR header={header} pageList={externoPageList} getScroll={this.getScroll} showLink/>
            </Route>
            <Route path="/endo-marketing">
              <PageListContainerWR header={header} pageList={endoPageList} getScroll={this.getScroll} showLink/>
            </Route>
            <Route path="/estudio">
              <PageListContainerWR header={header} pageList={estudioPageList} getScroll={this.getScroll} showLink/>
            </Route>
            <Route path="/comunicacion">
              <PageListContainerWR header={header} pageList={comuPageList} getScroll={this.getScroll} showLink/>
            </Route>
            <Route path="/conocenos">
              <PageListContainerWR header={header} pageList={conocenosPageList} getScroll={this.getScroll}/>
            </Route>
            <Route path="/entrenados">
              <PageListContainerWR header={header} pageList={entrenadosPageList} getScroll={this.getScroll} showLink/>
            </Route>
            <Route path="/contacto">
              <PageListContainerWR header={header} pageList={contactoPageList} getScroll={this.getScroll}/>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

let SiteAppWR = withRouter(SiteApp);
export default SiteAppWR;
