import React, {Component} from 'react';
import {RadioGroup, Radio} from 'react-radio-group';
import {StateFormFragment, postApi} from './Utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../css/Utils.css';
import getString from '../../Strings';

function QuestionTextEditor(props) {
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'formula'],
      ['clean']
    ],
  };
 
  return (
    <ReactQuill
      value={props.value}
      modules={modules}
      onChange={props.onChange}/>
  );
}

function QuestionTextDisplay(props) {
  return (
    <div className="QuestionTextDisplay">
      <ReactQuill 
        theme={"bubble"}
        value={props.text}
        readOnly={true}/>
    </div>
  );
}

class QuestionCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      main: {
        type: "",
      },
      multipleChoice: {
        number: 1
      },
      scale: {
        numPrompts: 1,
        numOptions: 1
      },
      // No props for text
      numberProps: {},
      date: {},
      submitting: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  async onSubmit(event) {
    event.preventDefault();

    this.setState({submitting: true});
    let body = {
      ...this.state.main
    };
    body.text = this.state.text;
    body.surveyId = this.props.surveyId;
    if (this.state.main.type === "multipleChoiceOne" || this.state.main.type === "multipleChoiceMany") {
      body.data = {
        ...this.state.multipleChoice
      };
    }
    if (this.state.main.type === "scaleOne") {
      body.data = {
        ...this.state.scale
      };
    }
    if (this.state.main.type === "textInput") {
      // No additional data
    }
    if (this.state.main.type === "numberInput") {
      body.data = {
        ...this.state.numberProps
      };
    }

    let res = await postApi("surveysApi/createQuestion", body);
    let data = await res.json();

    this.setState({
      formError: data.error,
      submitting: false,
    });

    if (data.success) {
      if (this.props.onSuccess) {
        this.props.onSuccess(data);
      }
    }
  }

  updateState(name, stateChange) {
    let newState = {...this.state[name]}
    // Manually apply changes to sub-object
    for (const key in stateChange) {
      newState[key] = stateChange[key];
    }
    let update = {}
    update[name] = newState;
    this.setState(update);
  }

  render() {
    // Type Form
    const typeFields = [
      {key: "type", name: "Type", type: "select", selectOptions: {
        multipleChoiceOne: getString("question_type_mcOne"),
        multipleChoiceMany: getString("question_type_mcMany"),
        scaleOne: getString("question_type_scaleOne"),
        textInput: getString("question_type_text"),
        numberInput: getString("question_type_number"),
      }},
      {key: "name", name: getString("question_create_name"), type: "text"},
    ];
    let typeForm = (
      <StateFormFragment
        name="main"
        updateState={this.updateState}
        fields={typeFields}
        values={this.state.main}
        errors={{}}/>
    );

    // Multiple Choice Options
    let multipleChoiceForm = null;
    if (this.state.main.type === "multipleChoiceOne" || this.state.main.type === "multipleChoiceMany") {
      const multipleChoiceFields = [
        {key: "number", name: getString("question_create_numChoices"), type: "number", props: {min: 1, max: 10}}
      ];
      for (let i=0; i<this.state.multipleChoice.number; i++) {
        multipleChoiceFields.push({
          key: "mc_option_" + i, name: getString("question_create_choice") + i, type: "text"
        });
      }
      multipleChoiceForm = (
        <StateFormFragment 
          name="multipleChoice"
          updateState={this.updateState}
          fields={multipleChoiceFields}
          values={this.state.multipleChoice}
          errors={{}}/>
      );
    }

    // Scale Options
    let scaleForm = null;
    if (this.state.main.type === "scaleOne") {
      const scalePromptFields = [
        {key: "numPrompts", name: getString("question_create_numPromps"), type: "number", props: {min: 1, max: 10}},
      ];
      for (let i=0; i<this.state.scale.numPrompts; i++) {
        scalePromptFields.push({
          key: "prompt_" + i, name: getString("question_create_prompt") + i, type: "text"
        });
      }
      const scaleOptionFields = [
        {key: "numOptions", name: getString("question_create_numOptions"), type: "number", props: {min: 1, max: 10}}
      ];
      for (let i=0; i<this.state.scale.numOptions; i++) {
        scaleOptionFields.push({
          key: "option_" + i, name: getString("question_create_option") + i, type: "text"
        });
      }
      scaleForm = (
        <div>
          <StateFormFragment
            name="scale"
            updateState={this.updateState}
            fields={scalePromptFields}
            values={this.state.scale}
            errors={{}}/>
          <StateFormFragment
            name="scale"
            updateState={this.updateState}
            fields={scaleOptionFields}
            values={this.state.scale}
            errors={{}}/>
        </div>
      );
    } 

    // Text Options - No additional options

    // Text/Number Objects
    let numberForm = null;
    if (this.state.main.type === "numberInput") {
      let numberFormFields = [
        {key: "min", name: getString("question_create_min"), type: "number"},
        {key: "max", name: getString("question_create_max"), type: "number"},     
      ];
      numberForm = (
        <div>
          <StateFormFragment
            name="numberProps"
            updateState={this.updateState}
            fields={numberFormFields}
            values={this.state.numberProps}
            errors={{}}/>
        </div>
      );
    }

    return (
      <div className="QuestionCreateDiv">
        <h2>{getString("question_create_title")}</h2>
        <form className="Form CreateQuestionForm" onSubmit={this.onSubmit}>
          <span className="RowLabel">{getString("question_create_text")}</span>
          <QuestionTextEditor 
            value={this.state.text}
            onChange={value => this.setState({text: value})}/>
          {typeForm}
          {multipleChoiceForm}
          {scaleForm}
          {numberForm}
        <div className="QuestionCreateActions">
            <input
              type="submit"
              className={"SubmitButton " + this.props.submitClass}
              disabled={this.state.submitting} value={getString("button_submit")}/>
            <button onClick={this.props.onCancel}>{getString("button_cancel")}</button>
          </div>
        </form>
      </div>
    );
  }
}

function QuestionMultipleChoiceOne(props) {
  let radios = [];
  for(const key in props.question.data) {
    if(key.startsWith("mc_option_")) {
      radios.push(
        <div key={key}>
          <span><Radio value={key}/>{props.question.data[key]}</span>
        </div>
      );
    }
  }
  return (
    <div className="QuestionDisplay">
      <QuestionTextDisplay text={props.question.text} />
      <RadioGroup
        selectedValue={props.selectedValue}
        onChange={value => props.onQuestionAnswered(props.question._id, value)}>
        {radios}
      </RadioGroup>
    </div>
  );
}

class QuestionMultipleChoiceMany extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.props.onQuestionAnswered(this.props.question._id, {});
  }

  onChange(event) {
    let newSelectedValues = this.props.selectedValues;
    newSelectedValues[event.target.name] = event.target.checked;
    this.props.onQuestionAnswered(this.props.question._id, newSelectedValues);
  }

  render() {
    const checkBox = (name, value, onChange) => {
      return <input type="checkbox" name={name} checked={value} onChange={onChange}/>
    }

    let checkboxes = [];
    for(const key in this.props.question.data) {
      if(key.startsWith("mc_option_")) {
        checkboxes.push(
          <div key={key}>
            <span>{checkBox(key, this.props.selectedValues[key] || false, this.onChange)}{this.props.question.data[key]}</span>
          </div>
        );
      }
    }
    return (
      <div className="QuestionDisplay">
        <QuestionTextDisplay text={this.props.question.text} />
        {checkboxes}
      </div>
    );
  }
}

class QuestionScaleOne extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.props.onQuestionAnswered(this.props.question._id, {});
  }

  onChange(event) {
    // Throw out deselect events
    if (!event.target.value) return;

    // Parse new selection
    let row = parseInt(event.target.name.split("/")[0]);
    let col = parseInt(event.target.name.split("/")[1]);
    
    let newSelectedValues = this.props.selectedValues;
    newSelectedValues[row] = col;
    this.props.onQuestionAnswered(this.props.question._id, newSelectedValues);
  }

  render() {
    const radio = (row, col, selected, onChange) => {
      return <input type="radio" name={row + "/" + col} checked={selected[row]===col} onChange={onChange}/>
    };

    const data = this.props.question.data;
    // Header (list of options at the top)
    let topLabels = [];
    topLabels.push(<th key={"empty"}></th>);
    for (let i=0; i<data.numOptions; i++) {
      topLabels.push(<th key={i}>{data["option_" + i]}</th>);
    }

    // Prompts
    let rows = [];
    for (let row=0; row<data.numPrompts; row++) {
      let radios = [];
      for (let col=0; col<data.numOptions; col++) {
        radios.push(<td key={col}>{radio(row, col, this.props.selectedValues, this.onChange)}</td>);
      }
      rows.push(
        <tr key={row}>
          <td>{data["prompt_" + row]}</td>
          {radios}
        </tr>
      );    
    }

    return (
      <div className="QuestionDisplay">
        <QuestionTextDisplay text={this.props.question.text} />
        <table>
          <thead>
            <tr>
              {topLabels}
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
}

function QuestionTextInput(props) {
  return (
    <div className="QuestionDisplay">
      <QuestionTextDisplay text={props.question.text} />
      <input
        type="text"
        value={props.currentValue}
        onChange={event => props.onQuestionAnswered(props.question._id, event.target.value)}/>
    </div>
  );
}

function QuestionNumberInput(props) {
  return (
    <div className="QuestionDisplay">
      <QuestionTextDisplay text={props.question.text} />
      <input
        type="number"
        value={props.currentValue}
        onChange={event => props.onQuestionAnswered(props.question._id, event.target.value)}
        min={props.question.data.min}
        max={props.question.data.max}/>
    </div>
  );
}

export {
  QuestionCreateForm,
  QuestionMultipleChoiceOne,
  QuestionMultipleChoiceMany,
  QuestionScaleOne,
  QuestionTextInput,
  QuestionNumberInput,
  QuestionTextDisplay,
};
