import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import SurveyApp from './survey/js/SurveyApp';
import SiteApp from './website/js/SiteApp';
import getString from './Strings'

class AdminPage extends Component {
  render() {
    return (
      <p>{getString("under_construction")}</p>
    );
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/survey">
            <SurveyApp />
          </Route>
          <Route path="/admin">
            <AdminPage />
          </Route>
          <Route exact path="/**">
            <SiteApp />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
