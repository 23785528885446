import React from 'react';
import '../css/EntrenadosPage.css';

import header from '../assets/entrenados/header.jpg';
import header_text from '../assets/entrenados/header_text.png';

import ofrecerte_img from '../assets/entrenados/ofrecerte.png';
import ayudaran from '../assets/entrenados/ayudaran.png';
import ventajas from '../assets/entrenados/ventajas.png';

import en_estudios from '../assets/entrenados/en_estudios.png';
import en_eval from '../assets/entrenados/en_eval.png';
import en_mark from '../assets/entrenados/en_mark.png';
import en_dis from '../assets/entrenados/en_dis.png';
import en_com from '../assets/entrenados/en_com.png';

import img_01 from '../assets/entrenados/01.png';
import img_02 from '../assets/entrenados/02.png';
import img_03 from '../assets/entrenados/03.png';
import img_04 from '../assets/entrenados/04.png';
import img_05 from '../assets/entrenados/05.png';

import section_1_icon_1 from '../assets/entrenados/section_1_icon_1.png';
import section_1_icon_2 from '../assets/entrenados/section_1_icon_2.png';
import section_2_icon_1 from '../assets/entrenados/section_2_icon_1.png';
import section_2_icon_2 from '../assets/entrenados/section_2_icon_2.png';
import section_3_icon_1 from '../assets/entrenados/section_3_icon_1.png';
import section_3_icon_2 from '../assets/entrenados/section_3_icon_2.png';
import section_4_icon_1 from '../assets/entrenados/section_4_icon_1.png';
import section_4_icon_2 from '../assets/entrenados/section_4_icon_2.png';

function NumberList(props) {
  return (
    <div className={"NumberList" + (props.isAqua ? " Aqua" : "")}>
      {props.showNumber && <img src={props.numberImg} alt=""></img>}
      <ul>
        {props.items.map((element, key) => <li key={key}>{element}</li>)}
      </ul>
      <div className={"Circle " + props.circleSize}></div>
    </div>
  );
}

function EntrenadosContent(props) {
  return (
    <div className={"EntrenadosContent " + props.num}>
      <div className="EntrenadosTitleHolder">
        <img className={"EntrenadosTitle Aqua" + props.num} src={props.titleOne} alt="" />
        <img className={"EntrenadosTitle Lime" + props.num} src={props.titleTwo} alt="" />
      </div>
      <div className="EntrenadosSection">
        <div>
          <img className="EntrenadosIcon" src={props.iconOne} alt="" />
        </div>
        <div>
          <NumberList isAqua={true} numberImg={props.numberImg} items={props.itemsOne} />
          <p><i>{props.bottomText}</i></p>
        </div><br/>
        <div className="BottomListTitleHolder">
          {props.itemsBottom.length > 0 ? <strong className="BottomListTitle">Modalidades de servicio</strong> : null}
        </div>
        <div className={"BottomList" + (props.greenBottom ? " Green" : "")}>
          <ul>
            {props.itemsBottom.map((element, key) => <li key={key}>{element}</li>)}
          </ul>
        </div>
      </div>
      <div className="EntrenadosSection">
        <div>
          <img className="EntrenadosIcon" src={props.iconTwo} alt="" />
        </div>
        <NumberList numberImg={props.numberImg} items={props.itemsTwo} circleSize={"Size" + props.num}/>
      </div>
    </div>
  );
}

function EntrenadosContentMobile(props) {
  return (
    <div className={"EntrenadosContent " + props.num}>
      <div className="EntrenadosTitleHolder">
        <img className={"EntrenadosTitle Aqua" + props.num} src={props.titleOne} alt="" />
      </div>
      <div className="EntrenadosSection">
        <NumberList isAqua={true} numberImg={props.numberImg} items={props.itemsOne} />
        <p><i>{props.bottomText}</i></p>
      </div>
    </div>
  );
}

function EntrenadosHeader(props) {
  return (
    <div className="PageDiv EntrenadosPage" onClick={() => props.goTo("/entrenados")}>
      <img className="HeaderImg" src={header} alt="" />
      <img className="HeaderTextImg" src={header_text} alt=""/>
    </div>
  );
}

function Page1(props) {
  const items_1 = [
    "Estudio de factibilidad de lanzamiento de producto",
    "Estudio de factibilidad de apertura de tienda",
    "Estudio de identificación del posicionamiento de marcas en el mercado",
    "Estudio de medición de campañas publicitarias",
  ];

  const items_2 = [
    "Segmentar tu mercado; conocer perfiles, hábitos. Interpretar datos cuantitativos y cualitativos con un análisis competitivo",
    "Controlar los riesgos que implica el lanzamiento de un nuevo producto o establecimiento",
    "Conocer donde se encuentra tu producto o servicio en relación a otros similares en el mercado",
    "Saber la posición de tu marca en la mente del consumidor",
    "Verificar si el mensaje de tu campaña se está transmitiendo, así como, obtener una retroalimentación para aumentar su efectividad",
  ];

  const items_bottom = [
    "Proyectos Ad Hoc",
  ];

  const bottomText = "Con un estudio de mercado podrás obtener más y mejor información para tomar decisiones acertadas";

  let Content = props.mobile ? EntrenadosContentMobile : EntrenadosContent;
  return (
    <div className="EntrenadosPage PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="OfImage" src={ofrecerte_img} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <Content
            num="1"
            titleOne={en_estudios}
            titleTwo={ayudaran}
            numberImg={img_01}
            bottomText={bottomText}
            iconOne={section_1_icon_1}
            iconTwo={section_1_icon_2}
            itemsOne={items_1}
            itemsTwo={items_2}
            itemsBottom={items_bottom} />
        </div>
      </div>
    </div>
  );
}

function Page2(props) {
  const items_1 = [
    "Evaluación de clientes satisfechos",
  ];

  const items_2 = [
    "Aumentar tus ventas",
    "Invertir de manera correcta",
    "Localizar a tu competencia",
    "Identificar tus fortalezas para sacarles jugo",
    "Detectar a tiempo tus debilidades",
    "Anticiparte a los cambios del mercado",
    "Fidelizar a tus clientes",
    "Mantener el éxito de tu negocio",
  ];

  const items_bottom = [
    "Encuesta en línea",
    "Encuesta personal de salida",
    "Encuesta de salida",
    "Encuesta telefónica",
  ];

  const bottomText = "Los clientes son la columna vertebral de toda empresa o negocio y al escucharlos recibimos una retroalimentación clara para lograr nuestros objetivos estratégicos";

  let Content = props.mobile ? EntrenadosContentMobile : EntrenadosContent;
  return (
    <div className="EntrenadosPage PageDiv">
      <div className="CenterVert">
        <Content
          num="2"
          titleOne={en_eval}
          titleTwo={ayudaran}
          numberImg={img_02}
          bottomText={bottomText}
          iconOne={section_2_icon_1}
          iconTwo={section_2_icon_2}
          itemsOne={items_1}
          itemsTwo={items_2}
          itemsBottom={items_bottom} />
      </div>
    </div>
  );
}

function Page3(props) {
  const items_1 = [
    "Estudios de clima organizacional",
    "Campañas de comunicación interna",
    "Videos institucionales",
    "Estudio de medición del impacto de campañas internas",
  ];

  const items_2 = [
    "Motivar e integrar al trabajador, para que se responsabilice de su función",
    "Mejorar la seguridad con la que tus trabajadores realizan su trabajo",
    "Aumentar el rendimiento de tus colaboradores",
    "Incrementar la permanencia del personal",
    "Transmitir información institucional",
  ];

  const items_bottom = [
    "Proyectos Ad Hoc",
  ];

  const bottomText = "El impacto de aplicar técnicas de mercadotecnia que enamoren a tus propios trabajadores (endomarketing) se traduce en ventajas como reducción de costos y éxito en los proyectos de tu organización";

  let Content = props.mobile ? EntrenadosContentMobile : EntrenadosContent;
  return (
    <div className="EntrenadosPage PageDiv">
      <div className="CenterVert">
        <Content
          num="3"
          titleOne={en_mark}
          titleTwo={ayudaran}
          numberImg={img_03}
          bottomText={bottomText}
          iconOne={section_3_icon_1}
          iconTwo={section_3_icon_2}
          itemsOne={items_1}
          itemsTwo={items_2}
          greenBottom={true}
          itemsBottom={items_bottom} />
      </div>
    </div>
  );
}

function Page4(props) {
  const items_1 = [
    "Diseño de marcas e imagen corporativa",
    "Diseño digital para correo electrónico y redes sociales",
    "Diseños profesionales de material gráfico",
    "Servicios de impresión",
    "Servicios de instalación",
  ];

  const items_2 = [
    "Diseños profesionales que detonan una conexión con el cliente interno o externo",
    "Material gráfico para tu empresa con especificaciones a la medida de sus necesidades y precios competitivos",
    "Ser una marca bien definida que refleje una clara compresión de tu negocio y del mercado",
  ];

  const items_bottom = [
    "Servicios de impresión",
    "Servicios de diseño",
    "Consultoría",
    "Proyectos Ad Hoc",
    "Servicios de instalación",
  ];

  const bottomText = "Complementar el conocimiento de expertos en psicología, mercadotecnia y publicidad permite el desarrollo de proyectos de diseño integrales que aseguran en mayor medida los resultados anhelados: Una influencia positiva sobre nuestro público objetivo";

  let Content = props.mobile ? EntrenadosContentMobile : EntrenadosContent;
  return (
    <div className="EntrenadosPage PageDiv">
      <div className="CenterVert">
        <Content
          num="4"
          titleOne={en_dis}
          titleTwo={ventajas}
          numberImg={img_04}
          bottomText={bottomText}
          iconOne={section_4_icon_1}
          iconTwo={section_4_icon_2}
          itemsOne={items_1}
          itemsTwo={items_2}
          greenBottom={true}
          itemsBottom={items_bottom} />
      </div>
    </div>
  );
}

function Page5(props) {
  const items_1 = [
    "Pizarrones y tableros de comunicación",
    "Señalamientos",
    "Ayudas visuales",
    "Decoración industrial",
    "Estructuras de aluminio",
    "Entre otros",
  ];

  const items_2 = [
    "Productos elaborados de manera integral, desde su diseño, producción e instalación orientados a cumplir con las necesidades del cliente",
    "Desarrollamos material de comunicación interna donde se combinan ideas de profesionales en mercadotecnia, psicología, y diseño logrando transmitir el mensage deseado",
  ];

  const items_bottom = [
    "Servicios de diseño",
    "Servicios de fabricación",
    "Servicios de instalación",
  ];

  const bottomText = "Somos especialistas en solucionar las necesidades de comunicación interna de nuestros clientes. Participamos en el diseño, producción e instalación de nuestros productos";

  let Content = props.mobile ? EntrenadosContentMobile : EntrenadosContent;
  return (
    <div className="EntrenadosPage PageDiv">
      <div className="CenterVert">
        <Content
          num="5"
          titleOne={en_com}
          titleTwo={ventajas}
          numberImg={img_05}
          bottomText={bottomText}
          iconOne={null} // No icons supplied
          iconTwo={null} // No icons supplied
          itemsOne={items_1}
          itemsTwo={items_2}
          greenBottom={true}
          itemsBottom={items_bottom} />
      </div>
    </div>
  );
}

let EntrenadosPageList = [
  <Page1 key="1" />,
  <Page2 key="2" />,
  <Page3 key="3" />,
  <Page4 key="4" />,
  <Page5 key="5" />,
];

const EntrenadosPageListPortait = [
  <Page1 key="1" mobile={true}/>,
  <Page2 key="2" mobile={true}/>,
  <Page3 key="3" mobile={true}/>,
  <Page4 key="4" mobile={true}/>,
  <Page5 key="5" mobile={true}/>,
];

export {
  EntrenadosHeader,
  EntrenadosPageList,
  EntrenadosPageListPortait,
}
