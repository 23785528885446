import React, {Component} from 'react';

import '../css/ContactoPage.css';
import {StateForm} from '../../survey/js/Utils';

import header from "../assets/contacto/header.jpg";
import header_text from "../assets/contacto/header_text.png";

import platicanos_title from '../assets/contacto/platicanos_title.png';
import phone_ico from '../assets/common/phone_ico.png';
import whats_ico from '../assets/common/whats_ico.png';
import email_ico from '../assets/common/email_ico.png';
import location_ico from '../assets/common/location_ico.png';

function StepSection() {
  let items = [
    "Contáctanos para platicar de tu idea y saber cómo nos podemos aliar",
    "Te haremos una propuesta que incluirá objectivos a medir, metodología, tiempos de ejecución e inversión",
    "Revisa con nosotros las dudas o ajustes necesarios para obtener tu proyecto a le medida",
  ];

  let pasos = [
    "PASO 1",
    "PASO 2",
    "PASO 3",
  ];

  return (
    <div className="StepSection ScrollAnim">
      <div className="PasoList">
      </div>
      <ul>
        {items.map((text, key) => <li key={key}>{text}<span>{pasos[key]}</span></li>)}
      </ul>
    </div>
  );
}

function DatosSection() {
  return (
    <div className="DatosSection">
      <table>
        <tbody>
          <tr className="BottomInfoHeader"><td></td><td><strong>DATOS DE CONTACTO</strong></td></tr>
          <tr>
            <td><img src={phone_ico} alt=""/></td>
            <td>[444] <strong>590 1731</strong></td></tr>
          <tr>
            <td><img src={whats_ico} alt=""/></td>
            <td>[+52] <strong>4442389449</strong></td></tr>
          <tr>
            <td><img src={email_ico} alt=""/></td>
            <td><strong>claudia</strong>@boladenievemkt.com</td></tr>
          <tr>
            <td><img src={location_ico} alt=""/></td>
            <td>Querétaro 340 | FFCC | 78300 | S.L.P. MX.</td></tr>
        </tbody>
      </table>
    </div>
  );
}

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
  }

  onSuccess() {
    alert("Enviado");
  }

  validate(state) {
    function validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    let errors = {};
    if (!state.name) {
      errors.name = "Name is required";
    }
    if (!state.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(state.email)) {
      errors.email = "Invalid Email"
    }
    if (!state.subject) {
      errors.subject = "Subject is required";
    }
    return errors;
  }

  render() {
    let fields = [
      {
        key: "name",
        name: <span>Nombre: <span className="aqua">*</span></span>,
        type: "text"
      }, {
        key: "email",
        name: <span>Correo electrónico: <span className="aqua">*</span></span>,
        type: "text"
      }, {
        key: "subject",
        name: <span>Asunto: <span className="aqua">*</span></span>,
        type: "text"
      }, {
        key: "message",
        name: "Mensaje",
        type: "textarea",
        props:{rows: "10"}
      },
    ];
    return (
      <StateForm
        className="ContactForm"
        fields={fields}
        validate={this.validate}
        api={"websiteApi/contactSubmit"}
        onSuccess={this.onSuccess}
        clearOnSuccess
        requireText={<span className="CampoText">* CAMPO OBLIGATORIO</span>}
        submitText="Enviar" />
    );
  }
}

function ContactoHeader(props)  {
  return (
    <div className="PageDiv ContactoPage" onClick={() => props.goTo("/contacto")}>
      <img className="HeaderImg" src={header} alt=""/>
      <img className="HeaderTextImg" src={header_text} alt=""/>
    </div>
  );
}

function Page1() {
  return (
    <div className="InfoPageBody PageDiv ContactoPage">
        <div className="Title">
          <div className="CenterVert">
            <img className="InfoTextImg" src={platicanos_title} alt=""></img>
          </div>
        </div>
        <div className="BodyUnderTitle">
          <div className="CenterVert" style={{textAlign: "left", transform: "translateY(-65%)"}}>
            <StepSection />
            <DatosSection />
          </div>
        </div>
    </div>
  );
};

function Page1p1() {
  return (
    <div className="InfoPageBody PageDiv ContactoPage">
        <div className="Title">
          <div className="CenterVert">
            <img className="InfoTextImg" src={platicanos_title} alt=""></img>
          </div>
        </div>
        <div className="BodyUnderTitle">
          <div className="CenterVert" style={{textAlign: "left", transform: "translateY(-65%)"}}>
            <StepSection />
          </div>
        </div>
    </div>
  );
};

function Page1p2() {
  return (
    <div className="InfoPageBody PageDiv ContactoPage">
      <div className="CenterVert" style={{textAlign: "left", transform: "translateY(-65%)"}}>
        <DatosSection />
      </div>
    </div>
  );
};

function Page2() {
  return (
    <div className="InfoPageBody PageDiv ContactoPage">
      <div className="CenterVert">
        <ContactForm />
      </div>
    </div>
  );
};

const ContactoPageList = [
  <Page1 key="1" />,
  <Page2 key="2" />,
];

const ContactoPageListPortait = [
  <Page1p1 key="1" />,
  <Page1p2 key="1" />,
  <Page2 key="2" />,
];

export {
  ContactoHeader,
  ContactoPageList,
  ContactoPageListPortait,
}
