import React from 'react';

import '../css/EndoPage.css';

import {MiddleSection} from "./Common";

import header from '../assets/endo/header.jpg';
import header_text from '../assets/endo/header_text.png';
import el_impacto_text from '../assets/endo/el_impacto_text.png';
import como_text from '../assets/common/como_text.png';

import img_0 from '../assets/endo/img_0.jpg';
import img_1 from '../assets/endo/img_1.jpg';
import img_2 from '../assets/endo/img_2.jpg';
import img_3 from '../assets/endo/img_3.jpg';
import img_4 from '../assets/endo/img_4.jpg';
import img_5 from '../assets/endo/img_5.jpg';
import img_6 from '../assets/endo/img_6.jpg';

function EndoHeader(props)  {
  return (
    <div className="PageDiv EndoPage" onClick={() => props.goTo("/endo-marketing")}>
      <img className="HeaderImg" src={header} alt=""/>
      <img className="HeaderTextImg" src={header_text} alt=""/>
    </div>
  );
}

function Page1()  {
  let items = [
    "Motivar e integrar al trabajador, para que se responsabilice de su función",
    "Mejorar la seguridad con la que tus trabajadores enfocan su trabajo",
    "Aumentar el rendimiento de tus colaboradores",
    "Incrementar la permanencia del personal",
    "Transmitir información institucional",
  ];

  return (
    <div className="PageDiv EndoPage">
      <div className="Title">
        <div className="CenterVert">
          <img className="InfoTextImg" src={el_impacto_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <MiddleSection items={items} className="MiddleSectionEndo"/>
        </div>
      </div>
    </div>
  );
}

function ComoTile(props) {
  return (
    <div className={"ComoTile Endo" + props.suffix}>
      <img src={props.image} alt=""/>
      <div>
        <span>
          {props.text}
        </span>
      </div>
    </div>
  );
}

function ComoTileGroupFull()  {
  let items = [
    "ESTUDIOS DE CLIMA ORGANIZACIONAL",
    "CAMPAÑAS DE COMUNICACIÓN INTERNA",
    "CAMPAÑAS DE CALIDAD",
    "CAMPAÑAS DE 5'S",
    "CAMPAÑAS DE SEGURIDAD INDUSTRIAL",
    "VIDEOS INSTITUCIONALES",
    "ESTUDIO DE MEDICIÓN DEL IMPACTO DE CAMPAÑAS INTERNAS",
  ];

  let images = [
    img_0,
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    img_6,
  ];

  return (
    <div className="ComoTileGroup Endo">
      <ComoTile suffix="0" text={items[0]} image={images[0]}/>
      <ComoTile suffix="0" text={items[1]} image={images[1]}/>
      <ComoTile suffix="0" text={items[2]} image={images[2]}/>
      <ComoTile suffix="0" text={items[3]} image={images[3]}/>
      <br/>
      <ComoTile suffix="1" text={items[4]} image={images[4]}/>
      <ComoTile suffix="1" text={items[5]} image={images[5]}/>
      <ComoTile suffix="1" text={items[6]} image={images[6]}/>
    </div>
  );
}

function ComoTileGroupP1()  {
  let items = [
    "ESTUDIOS DE CLIMA ORGANIZACIONAL",
    "CAMPAÑAS DE COMUNICACIÓN INTERNA",
  ];

  let images = [
    img_0,
    img_1,
  ];

  return (
    <div className="ComoTileGroupHalf Endo">
      <ComoTile suffix="1" text={items[0]} image={images[0]}/>
      <ComoTile suffix="1" text={items[1]} image={images[1]}/>
    </div>
  );
}

function ComoTileGroupP2()  {
  let items = [
    "CAMPAÑAS DE CALIDAD",
    "CAMPAÑAS DE SEGURIDAD INDUSTRIAL",
  ];

  let images = [
    img_2,
    img_4,
  ];

  return (
    <div className="ComoTileGroupHalf Endo">
      <ComoTile suffix="1" text={items[0]} image={images[0]}/>
      <ComoTile suffix="1" text={items[1]} image={images[1]}/>
    </div>
  );
}

function ComoTileGroupP3()  {
  let items = [
    "CAMPAÑAS DE 5'S",
    "VIDEOS INSTITUCIONALES",
    "ESTUDIO DE MEDICIÓN DEL IMPACTO DE CAMPAÑAS INTERNAS",
  ];

  let images = [
    img_3,
    img_5,
    img_6,
  ];

  return (
    <div className="ComoTileGroupHalf Endo">
      <ComoTile suffix="2" text={items[0]} image={images[0]}/>
      <ComoTile suffix="2" text={items[1]} image={images[1]}/>
      <ComoTile suffix="2" text={items[2]} image={images[2]}/>
    </div>
  );
}

function Page2()  {
  return (
    <div className="PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="ComoTileGroupHolder">
          <ComoTileGroupFull />
        </div>
      </div>
    </div>
  );
}

function Page2p1()  {
  return (
    <div className="PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="ComoTileGroupHolder">
          <ComoTileGroupP1 />
        </div>
      </div>
    </div>
  );
}

function Page2p2()  {
  return (
    <div className="PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="ComoTileGroupHolder">
          <ComoTileGroupP2 />
        </div>
      </div>
    </div>
  );
}

function Page2p3()  {
  return (
    <div className="PageDiv">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="ComoTileGroupHolder">
          <ComoTileGroupP3 />
        </div>
      </div>
    </div>
  );
}

const EndoPageList = [
  <Page1 key="1"/>,
  <Page2 key="2"/>,
];

const EndoPageListPortait = [
  <Page1 key="1"/>,
  <Page2p1 key="2"/>,
  <Page2p2 key="3"/>,
  <Page2p3 key="4"/>,
];

export {
  EndoHeader,
  EndoPageList,
  EndoPageListPortait,
}
