var language = "english";

let Strings = {
  english: {
    // HOME
    home_welcome: "Welcome!",
    home_instructions_0: "To view and take public surveys, select the \"Surveys\" tab in the header",
    home_instructions_1: "To create and modify surveys, or view results, please log in or register and use the tabs to navigate",
    // ADMIN
    under_construction: "Under construction...",
    // HEADER
    user_hello: "Hello ",
    user_logout: "Logout",
    button_login: "Login",
    button_register: "Register",
    tab_home: "Home",
    tab_surveys: "Surveys",
    tab_results: "Results",
    tab_admin: "Admin",
    // QUESTIONS
    question_create_title: "Create Question",
    question_create_text: "Question Text",
    question_type_mcOne: "Multiple Choice (One)",
    question_type_mcMany: "Multiple Choice (Many)",
    question_type_scaleOne: "Scale (One)",
    question_type_text: "Text Input",
    question_type_number: "Number Input",
    question_create_name: "Question Name",
    question_create_numChoices: "Number of choices",
    question_create_choice: "Choice ",
    question_create_numPrompts: "Number of Prompts",
    question_create_prompt: "Prompt ",
    question_create_numOptions: "Number of Options",
    question_create_option: "Option ",
    question_create_min: "Minimum",
    question_create_max: "Maximum",
    // RESULTS
    results_select_version: "Select a version",
    results_survey_name: "Survey Name",
    results_download_results: "Download Full Results",
    results_view_summary: "View Results Summary",
    results_loading: "Loading Results...",
    results_total_responses: "Total Responses: ",
    // BUTTONS
    button_submit: "Submit",
    button_cancel: "Cancel",
    button_done: "Done",
    button_delete: "Delete",
    button_start: "Start",
    generic_loading: "Loading...",
    // SURVEY CREATE
    survey_page_title: "Surveys",
    survey_page_list: "List Surveys",
    survey_page_create: "Create New",
    survey_page_take: "Take Survey",
    survey_page_edit: "Edit Survey",
    survey_create_name: "Name",
    survey_create_desc: "Description",
    survey_create_msg_welcome: "Welcome Message",
    survey_create_msg_finished: "Finished Message",
    survey_list_take: "Take",
    survey_list_edit: "Edit",
    survey_header_name: "Name",
    survey_header_desc: "Description",
    survey_header_created: "Created",
    survey_header_view: "View",
    survey_header_edit: "Edit",
    survey_header_delete: "Delete",
    survey_info_created: "Created",
    survey_info_link: "Link to access",
    survey_info_num: "Total Questions",
    survey_info_version: "Version",
    survey_info_title: "Survey Info",
    button_create_question: "Create New Question",
    question_list_title: "Questions",
    question_list_type: "Type",
    question_list_text: "Text",
    question_list_answers: "Answers",
    question_list_delete: "Delete",
    // USER FORMS
    user_form_username: "Username",
    user_form_password: "Password",
    user_form_password_confirm: "Confirm Password",
    user_form_match_error: "Passwords don't match",
    // OTHER
    form_select_default: "Select an option",
  },

  spanish: {
    // HOME
    home_welcome: "",
    home_instructions_0: "",
    home_instructions_1: "",
    // ADMIN
    under_construction: "",
    // HEADER
    user_hello: "",
    user_logout: "",
    button_login: "",
    button_register: "",
    tab_home: "",
    tab_surveys: "",
    tab_results: "",
    tab_admin: "",
    // QUESTIONS
    question_create_title: "",
    question_create_text: "",
    question_type_mcOne: "",
    question_type_mcMany: "",
    question_type_scaleOne: "",
    question_type_text: "",
    question_type_number: "",
    question_create_name: "",
    question_create_numChoices: "",
    question_create_choice: "",
    question_create_numPrompts: "",
    question_create_prompt: "",
    question_create_numOptions: "",
    question_create_option: "",
    question_create_min: "",
    question_create_max: "",
    // RESULTS
    results_select_version: "",
    results_survey_name: "",
    results_download_results: "",
    results_view_summary: "",
    results_loading: "",
    results_total_responses: "",
    // BUTTONS
    button_submit: "",
    button_cancel: "",
    button_done: "",
    button_delete: "",
    button_start: "",
    generic_loading: "",
    // SURVEY CREATE
    survey_page_title: "",
    survey_page_list: "",
    survey_page_create: "",
    survey_page_take: "",
    survey_page_edit: "",
    survey_create_name: "",
    survey_create_desc: "",
    survey_create_msg_welcome: "",
    survey_create_msg_finished: "",
    survey_list_take: "",
    survey_list_edit: "",
    survey_header_name: "",
    survey_header_desc: "",
    survey_header_created: "",
    survey_header_view: "",
    survey_header_edit: "",
    survey_header_delete: "",
    survey_info_created: "",
    survey_info_link: "",
    survey_info_num: "",
    survey_info_version: "",
    survey_info_title: "",
    button_create_question: "",
    question_list_title: "",
    question_list_type: "",
    question_list_text: "",
    question_list_answers: "",
    question_list_delete: "",
    // USER FORMS
    user_form_username: "",
    user_form_password: "",
    user_form_password_confirm: "",
    user_form_match_error: "",
    // OTHER
    form_select_default: "",  
  }
};

function getString(key) {
  return Strings[language][key];
}

export default getString;