import React, {Component} from 'react';
import {getApi, apiUrl} from './Utils';
import {Errors} from './errors'
import {Chart} from 'chart.js';

import { withRouter } from 'react-router-dom';
import getString from '../../Strings';

class VersionSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVersion: ""
    }
  }

  render() {
    let options = [
      <option key="" value="">{getString("results_select_version")}</option>
    ];
    for (const key in this.props.survey.versionNames) {
      options.push(<option value={key} key={key}>{this.props.survey.versionNames[key]}</option>);
    }
    const input = (
      <select
        onChange={event => this.setState({selectedVersion: event.target.value})}
        value={this.state.selectedVersion}>
        {options}
      </select>
    );

    return (
      <div>
        {input}
        <button 
          onClick={() => this.props.onSelect(
            this.props.survey._id,
            this.state.selectedVersion
          )}
        disabled={this.props.disabled}>{getString("button_submit")}</button>
      </div>
    );
  }
}

function SurveyResultListItem(props) {
  const survey = props.survey;
  return (
    <tr>
      <td>{survey.name}</td>
      <td><VersionSelector survey={survey} onSelect={props.downloadResults}/></td>
      <td><VersionSelector survey={survey} onSelect={props.viewResults}/></td>
    </tr>
  );
}

function SurveyResultList(props) {
  let listItems = [];
  props.surveys.forEach(survey => listItems.push(
    <SurveyResultListItem
      key={survey._id}
      survey={survey}
      downloadResults={props.downloadResults}
      viewResults={props.viewResults}/>
  ))
  return (
    <table className="ListTable">
      <thead>
        <tr>
          <th>{getString("results_survey_name")}</th>
          <th>{getString("results_download_results")}</th>
          <th>{getString("results_view_summary")}</th>
        </tr>
      </thead>
      <tbody>
        {listItems}
      </tbody>
    </table>
  );
}

class ScaleBarGraph extends Component {
  componentDidMount() {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");
    const data = this.props.response.responseData;
    
    let labels = [];
    let colors = [
      '#20639B',
      '#3CAEA3',
      '#ED553B',
      "#7A8594",
      "#7A8594",
      "#00FFC5",
    ];

    for (let i=0; i<data.numOptions; i++) {
      labels.push(data["option_"+i]);
    }

    let datasets = [];
    for (let i=0; i<data.numPrompts; i++) {
      datasets.push({
        label: data["prompt_" + i].text,
        data: data["prompt_" + i].counts,
        backgroundColor: colors[i],
        borderColor: colors[i],
        borderWidth: 1
      });
    }

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  render() {
    return (
      <div>
        <h3>{this.props.response.questionName}</h3>
        <canvas ref="canvas" width={400} height={400}/>
      </div>
    );
  }  
}

class MultipleChoiceBarGraph extends Component {
  componentDidMount() {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");
    const data = this.props.response.responseData;
    
    let labels = [];
    let counts = [];

    for (let i=0; i<data.number; i++) {
      let key = "mc_option_" + i;
      labels.push(data[key].text);
      counts.push(data[key].count);
    }

    new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [{
          label: "",
          data: counts,
          backgroundColor: '#20639B',
          borderColor: '#20639B',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  render() {
    return (
      <div>
        <h3>{this.props.response.questionName}</h3>
        <canvas ref="canvas" width={400} height={400}/>
      </div>
    );
  }
}

class SurveyResultsSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseData: null
    }
  }

  async componentDidMount() {
    let res = await getApi("responsesApi/aggregateResponses", {
      surveyId: this.props.surveyId,
      version: this.props.version
    });
    let data = await res.json();
    this.setState({
      responseData: data.data
    });
  }

  render() {
    if (!this.state.responseData) {
      return <p>{getString("results_loading")}</p>
    }
    let responses = this.state.responseData.aggregate; 

    let graphs = [];
    Object.keys(responses).forEach(questionId => {
      let response = responses[questionId];
      if (response.type === "multipleChoiceOne" || response.type === "multipleChoiceMany") {
        graphs.push(
          <MultipleChoiceBarGraph key={questionId} response={response}/>
        );
      } else if (response.type === "scaleOne") {
        graphs.push(
          <ScaleBarGraph key={questionId} response={response}/>
        );
      }
    })

    return (
      <div>
        <h1>{this.state.responseData.surveyName}</h1>
        <h2>{getString("results_total_responses")}{this.state.responseData.totalResponses}</h2>
        <button onClick={this.props.backToList}>{getString("button_done")}</button>
        {graphs}
      </div>
    );
  }
}

class ResultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: null,
      viewResults: null
    }

    this.viewResults = this.viewResults.bind(this);
  }

  async componentDidMount() {
    let response = await getApi("surveysApi/listSurveys", {onlyMine: true});
    let data = await response.json();

    if (data.errorCode === Errors.NO_USER_LOGGED_IN.errorCode) {
      this.props.localLogout();
      this.props.history.push("/survey/");
      return;
    }

    this.setState({
      surveys: data.surveys
    })

    this.downloadResults = this.downloadResults.bind(this);
    this.viewResults = this.viewResults.bind(this);
  }

  downloadResults(surveyId, version) {
    let url = apiUrl("responsesApi/downloadResponses");
    url.searchParams.append("surveyId", surveyId);
    url.searchParams.append("version", version);
    window.open(url, "_blank")
  }

  viewResults(surveyId, version) {
    this.setState({viewResults: {surveyId, version}});
  }

  render() {
    if (this.state.surveys == null) {
      return (
        <p>
          {getString("generic_loading")}
        </p>
      );
    }

    return (
      <div className="Page">
        {!this.state.viewResults && 
          <SurveyResultList 
            surveys={this.state.surveys}
            downloadResults={this.downloadResults}
            viewResults={this.viewResults}/>
        }
        {this.state.viewResults && 
          <SurveyResultsSummary 
            backToList={() => {this.setState({viewResults: null})}}
            {...this.state.viewResults}/>
        }
      </div>
    );
  }
}

let ResultsPageWithRouter = withRouter(ResultsPage);

export {ResultsPageWithRouter}
