import React, { Component } from 'react';
import '../css/ConocenosPage.css';

import header from '../assets/conocenos/header.jpg';
import header_text from '../assets/conocenos/header_text.png';
import cono_cenos_img from '../assets/conocenos/cono_cenos.png';
import cono_cenos_line_img from '../assets/conocenos/cono_cenos_line.png';
import mision_img from '../assets/conocenos/mision.png';
import vision_img from '../assets/conocenos/vision.png';
import nuestro_img from '../assets/conocenos/nuestro.png';

import portrait_0 from '../assets/conocenos/portrait_0.jpg';
import portrait_1 from '../assets/conocenos/portrait_1.jpg';
import portrait_2 from '../assets/conocenos/portrait_2.jpg';

import porque_img from '../assets/conocenos/porque.png';
import a_img from '../assets/conocenos/a_img.png';
import b_img from '../assets/conocenos/b_img.png';
import a_ltr from '../assets/conocenos/a.png';
import b_ltr from '../assets/conocenos/b.png';

class Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "n"
    }
  }

  componentDidMount() {
    // Auto-switch tab
    this.interval = setInterval(() => {
      if (this.state.activeTab === "e") {
        this.setState({activeTab: "n"});
      } else {
        this.setState({activeTab: "e"});
      }
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let conocenosImage = cono_cenos_img;
    if (window.innerWidth < window.innerHeight) {
      conocenosImage = cono_cenos_line_img;
    }

    let strings = {
      "n" : "Ser el principal aliado de nuestros clientes, proporcionando herramientas clave para la toma de decisiones y diseño de estrategias.",
      "e" : "Convertirnos en la principal Agencia de Marketing proveedora de información así como de productos de comunicación visual del mercado.",
    }

    return (
      <div className="BodySection">
        <div className="Section1">
          <div className="ConocenosHolder">
            <div className="aquarect"></div>
            <img className="ScrollAnim" src={conocenosImage} alt=""></img>
          </div>
          <div className="SwitchHolder">
            <div className="Section1Header">
              <span className={"Section1HeaderLabel" + (this.state.activeTab === "n" ? " active" : "")} onClick={() => this.setState({activeTab: "n"})}>Nuestra esencia </span>
              <span className={"Section1HeaderLabel" + (this.state.activeTab === "e" ? " active" : "")} onClick={() => this.setState({activeTab: "e"})}>El escenario a futuro</span>
            </div>
            <div className="SwitchContentHolder">
              <div className={"SwitchContent" + (this.state.activeTab === "n" ? "" : " Hidden")}>
                <img src={mision_img} alt=""></img>
                <p className="Section1Text">{strings["n"]}</p>
              </div>
              <div className={"SwitchContent" + (this.state.activeTab === "e" ? "" : " Hidden")}>
                <img src={vision_img} alt=""></img>
                <p className="Section1Text">{strings["e"]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function Section2()  {
  return (
    <div className="BodySection">
      <div className="Section2">
        <div className="NuestroGroup">
          <img className="ScrollAnim" src={nuestro_img} alt=""></img>
          <div className="aquarect"></div>
        </div>
        <br/>
        <p className="SmallParagraph">
          Estamos conformados por un equipo interdisciplinario de mercadólogos,
          administradores, diseñadores gráficos y personal de campo
        </p>
        <br/>
        <p className="BigParagraph">
          Somos aliados de nuestros clientes, comprometidos en el logro de resultados; combinamos su experiencia con nuestro enfoque especializado
        </p>
      </div>
    </div>
  );
}

function Portrait(props)  {
  return (
    <div className="Portrait ScrollAnim2">
      <img src={props.img} alt=""></img>
      <div className="NameDiv">{props.name}</div>
      <div className="DescDiv"><p>{props.title}</p></div>
    </div>
  );
}

function Section3()  {
  return (
    <div className="Section3">
      <Portrait img={portrait_0} name="Claudia Sarreón" title="LÍDER DE PROYECTOS Y CONTACTO CON EL CLIENTE"/>
      <Portrait img={portrait_1} name="Mayra Sarreón" title="LÍDER DE PRODUCCIÓN E INVESTIGACIÓN DE CAMPO"/>
      <Portrait img={portrait_2} name="Daniel Juárez Cisneros" title="LÍDER DE DISEÑO Y PUBLICIDAD"/>
    </div>
  );
}

function Section3p1()  {
  return (
    <div className="Section3">
      <Portrait img={portrait_0} name="Claudia Sarreón" title="LÍDER DE PROYECTOS Y CONTACTO CON EL CLIENTE"/>
    </div>
  );
}

function Section3p2()  {
  return (
    <div className="Section3">
      <Portrait img={portrait_1} name="Mayra Sarreón" title="LÍDER DE PRODUCCIÓN E INVESTIGACIÓN DE CAMPO"/>
      <Portrait img={portrait_2} name="Daniel Juárez Cisneros" title="LÍDER DE DISEÑO Y PUBLICIDAD"/>
    </div>
  );
}

function Section4Sub(props)  {
  return (
    <div className="Section4Sub ScrollAnim">
      <img src={props.icon} className="Section4Icon" alt=""></img>
      <div>
        <div>
          <img className="LetterImg" src={props.letter} alt=""></img>
          <span>{props.title}</span>
        </div>
        <ul>
          {props.items.map((element, key) => <li key={key}>{element}</li>)}
        </ul>
      </div>
    </div>
  );
}

function Section4()  {
  let itemsA = [
    "Veracidad en los datos.",
    "Objetividad en la información recopilada.",
    "Imparcialidad en la interpretación de datos.",
    "Calidad en resultados y creación de estrategias.",
  ];

  let itemsB = [
    "Soluciones objetivas de comunicación visual.",
    "Asesoría y profesionalismo en diseños.",
    "Dedicación y cuidado en los detalles.",
    "Coherencia en la linea de diseño de la marca a lo largo del tiempo.",
  ];

  return (
    <div className="Section4">
      <Section4Sub icon={a_img} letter={a_ltr} items={itemsA} title="INVESTIGACIÓN DE MERCADOS"/>
      <Section4Sub icon={b_img} letter={b_ltr} items={itemsB} title="DISEÑO GRÁFICO E IMPRESIÓN"/>
    </div>
  );
}

function Section5()  {
  return (
    <div className="Section5">
      <span>ESPECIALIZADOS EN</span>
      <div>
        Pequeñas, medianas y grandes empresas de productos y servicios<br/>
        <strong>Industria manufacturera</strong><br/>
        Dependencias de Gobierno/Partidos políticos<br/>
        <strong>Instituciones educativas</strong><br/>
        Constructoras<br/>
        <strong>Marcas de Corporativos</strong><br/>
        B2B
      </div>
      <span>COBERTURA</span>
      <div>
        Estamos localizados en San Luis Potosí Capital, lo que nos permite atender a toda la República Mexicana por nuestra ubicación centrica
      </div>
    </div>
  );
}

function ConocenosHeader(props)  {
  return (
    <div className="PageDiv ConocenosPage" onClick={() => props.goTo("/conocenos")}>
      <img className="HeaderImg" src={header} alt=""/>
      <img className="HeaderTextImg" src={header_text} alt=""/>
    </div>
  );
}

function Page1() {
  return (
    <div className="PageDiv">
      <div className="CenterVert ConocenosPage">
        <div className="GreenDiv">Somos especialistas en técnicas de recolección e investigación de datos y su interpretación</div>
        <div className="GreenDiv">Única en San Luis Potosí  en hacer mercadotecnia interna (endomarketing) enfocada a la industria</div>
        <div className="GreenDiv">8 años colaborando con el éxito de nuestros socios comerciales</div>
        <Section1 />
      </div>
    </div>
  );
}

function Page1p1() {
  return (
    <div className="PageDiv">
      <div className="CenterVert ConocenosPage">
        <div className="GreenDiv">Somos especialistas en técnicas de recolección e investigación de datos y su interpretación</div>
        <div className="GreenDiv">Única en San Luis Potosí  en hacer mercadotecnia interna (endomarketing) enfocada a la industria</div>
        <div className="GreenDiv">8 años colaborando con el éxito de nuestros socios comerciales</div>
      </div>
    </div>
  );
}

function Page1p2() {
  return (
    <div className="PageDiv">
      <div className="CenterVert ConocenosPage">
        <Section1 />
      </div>
    </div>
  );
}

function Page2() {
  return (
    <div className="PageDiv">
      <Section2 />
      <Section3 />
    </div>
  );
}

function Page2p1() {
  return (
    <div className="PageDiv">
      <Section2 />
      <Section3p1 />
    </div>
  );
}

function Page2p2() {
  return (
    <div className="PageDiv">
      <div className="CenterVert">
        <Section3p2 />
      </div>
    </div>
  );
}

function Page3() {
  return (
    <div className="PageDiv ConocenosPage">
      <div className="Title">
        <div className="CenterVert">
          <img className="ScrollAnim" src={porque_img} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <Section4 />
          <Section5 />
        </div>
      </div>
    </div>
  );
}

function Page3p1() {
  return (
    <div className="PageDiv ConocenosPage">
      <div className="Title">
        <div className="CenterVert">
          <img src={porque_img} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <Section4 />
        </div>
      </div>
    </div>
  );
}

function Page3p2() {
  return (
    <div className="PageDiv ConocenosPage">
      <div className="Title">
        <div className="CenterVert">
          <img src={porque_img} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <Section5 />
        </div>
      </div>
    </div>
  );
}

const ConocenosPageList = [
  <Page1 key="1"/>,
  <Page2 key="2"/>,
  <Page3 key="3"/>,
];

const ConocenosPageListNarrow = [
  <Page1 key="1"/>,
  <Page2 key="2"/>,
  <Page3p1 key="3"/>,
  <Page3p2 key="4"/>,
];

const ConocenosPageListPortrait = [
  <Page1p1 key="1"/>,
  <Page1p2 key="2"/>,
  <Page2p1 key="2"/>,
  <Page2p2 key="2"/>,
  <Page3p1 key="3"/>,
  <Page3p2 key="4"/>,
];

export {
  ConocenosHeader,
  ConocenosPageList,
  ConocenosPageListNarrow,
  ConocenosPageListPortrait,
}
