import React, {Component} from 'react';
import {postApi, getApi, StateForm} from './Utils';
import {
  QuestionCreateForm,
  QuestionMultipleChoiceOne,
  QuestionMultipleChoiceMany,
  QuestionScaleOne,
  QuestionTextInput,
  QuestionNumberInput,
  QuestionTextDisplay,
} from './Questions';
import {
  Link,
  withRouter,
  Switch,
  Route
} from 'react-router-dom';
import '../css/Surveys.css';
import '../css/Questions.css';
import getString from '../../Strings';

function getSurveyCreatedDate(survey) {
  let m = new Date(parseInt(survey._id.substring(0, 8), 16) * 1000);
  var dateString = m.getUTCFullYear() +"/"+ (m.getUTCMonth()+1) +"/"+ m.getUTCDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() + ":" + m.getUTCSeconds();
  return dateString;
}

function SurveyCreateForm(props) {
  let fields = [
    {key: "name", name: getString("survey_create_name"), type: "text"},
    {key: "description", name: getString("survey_create_desc"), type: "textarea"},
    {key: "welcomeMessage", name: getString("survey_create_msg_welcome"), type: "textarea"},
    {key: "finishedMessage", name: getString("survey_create_msg_finished"), type: "textarea"},
  ];
  return (
      <StateForm
        fields={fields}
        api={"surveysApi/createSurvey"}
        onSuccess={props.onSuccess} />
  );
}

function SurveyListItem(props) {
  var dateString = getSurveyCreatedDate(props.survey);
  let canEdit = props.user && (props.survey.creator === props.user._id || props.user.isAdmin);
  return (
    <tr>
      <td>{props.survey.name}</td>
      <td>{props.survey.description}</td>
      <td>{dateString}</td>
      <td><Link to={"/survey/surveys/take/" + props.survey._id}>{getString("survey_list_take")}</Link></td>
      <td>
        {
          canEdit ?
            <Link to={"/survey/surveys/edit/" + props.survey._id}
              disabled={!canEdit}>{getString("survey_list_edit")}</Link> :
            <span>X</span>
        }

      </td>
      <td><button disabled={!canEdit} onClick={() => props.deleteSurvey(props.survey._id)}>{getString("button_delete")}</button></td>
    </tr>
  );
}

class SurveyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: null
    };
    this.deleteSurvey = this.deleteSurvey.bind(this);
    this.refreshSurveys = this.refreshSurveys.bind(this);
  }

  componentDidMount() {
    this.refreshSurveys();
  }

  async deleteSurvey(surveyId) {
    await postApi("surveysApi/deleteSurvey", {
      surveyId: surveyId,
    });
    this.refreshSurveys();
  }

  async refreshSurveys() {
    let res = await getApi("surveysApi/listSurveys");
    let data = await res.json();
    this.setState({
      surveys: data.surveys
    });
  }

  render() {
    if (this.state.surveys === null) {
      return <p>{getString("generic_loading")}</p>;
    }

    return (
      <table className="ListTable">
        <thead>
          <tr>
            <th>{getString("survey_header_name")}</th>
            <th>{getString("survey_header_desc")}</th>
            <th>{getString("survey_header_created")}</th>
            <th>{getString("survey_header_view")}</th>
            <th>{getString("survey_header_edit")}</th>
            <th>{getString("survey_header_delete")}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.surveys.map((survey) =>
            <SurveyListItem
              key={survey._id}
              survey={survey}
              user={this.props.user}
              deleteSurvey={this.deleteSurvey}/>)}
        </tbody>
      </table>
    );
  }
}

class EditSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: null,
      creatingQuestion: false,
    }
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.refreshSurveyData = this.refreshSurveyData.bind(this);
    this.onQuestionCreated = this.onQuestionCreated.bind(this);
    this.onQuestionUpdated = this.onQuestionUpdated.bind(this);
  }

  componentDidMount() {
    this.refreshSurveyData();
  }

  async refreshSurveyData() {
    let res = await getApi("surveysApi/loadSurvey", {
      surveyId: this.props.match.params.id,
    });
    let data = await res.json();
    if (data.survey) {
      let canEdit = this.props.user && (data.survey.creator === this.props.user._id  || this.props.user.isAdmin);
      if (canEdit) {
        this.setState({
          survey: data.survey,
        });
      } else {
        this.props.history.push("/survey/surveys");
      }
    } else {
      console.log(data.error);
    }
  }

  async deleteQuestion(questionId) {
    await postApi("surveysApi/deleteQuestion", {
      surveyId: this.props.match.params.id,
      questionId: questionId,
    });
    this.refreshSurveyData();
  }

  onQuestionCreated() {
    this.setState({creatingQuestion: false});
    this.refreshSurveyData();
  }

  onQuestionUpdated() {
    this.setState({edittingQuestion: null});
  }

  render() {
    if (!this.state.survey) {
      return <p>Loading...</p>;
    }
    const surveyInfoLeft = (
      <table className="ListTable">
        <tbody>
          <tr><td>{getString("survey_create_name")}</td><td>{this.state.survey.name}</td></tr>
          <tr><td>{getString("survey_create_desc")}</td><td>{this.state.survey.description}</td></tr>
          <tr><td>{getString("survey_create_msg_welcome")}</td><td>{this.state.survey.welcomeMessage}</td></tr>
          <tr><td>{getString("survey_create_msg_finished")}</td><td>{this.state.survey.finishedMessage}</td></tr>
        </tbody>
      </table>
    );

    const surveyInfoRight = (
      <table className="ListTable">
        <tbody>
          <tr><td>{getString("survey_info_created")}</td><td>{getSurveyCreatedDate(this.state.survey)}</td></tr>
          <tr><td>{getString("survey_info_link")}</td><td><Link to={"/survey/surveys/take/" + this.state.survey._id}>{getString("survey_list_take")}</Link></td></tr>
          <tr><td>{getString("survey_info_num")}</td><td>{this.state.survey.questions.length}</td></tr>
          <tr><td>{getString("survey_info_version")}</td><td>{""}</td></tr>
        </tbody>
      </table>
    );

    return (
      <div>
        <h2>{getString("survey_info_title")}</h2>
        <div className="SurveyInfoDiv">
          {surveyInfoLeft}
          {surveyInfoRight}
        </div>
        {!this.state.creatingQuestion && <button
          className="CreateQuestionButton"
          onClick={() => this.setState({creatingQuestion: true})}>{getString("button_create_question")}</button>}
        {this.state.creatingQuestion && <QuestionCreateForm
          surveyId={this.state.survey._id}
          onSuccess={this.onQuestionCreated}
          onCancel={() => this.setState({creatingQuestion: false})}/>}
         <h2>{getString("question_list_title")}</h2>
        <QuestionList survey={this.state.survey} deleteQuestion={this.deleteQuestion}/>
      </div>
    );
  }
}

function QuestionListItem(props) {
  let answers = "";
  if (props.question.type === "multipleChoiceOne" || props.question.type === "multipleChoiceMany") {
    for (const key in props.question.data) {
      if(key.startsWith("mc_option_")) {
        answers += props.question.data[key] + "/";
      }
    }
  }

  if (props.question.type === "scaleOne") {
    let options = "";
    let prompts = "";
    for (const key in props.question.data) {
      if(key.startsWith("option_")) {
        options += props.question.data[key] + "/";
      }
      if(key.startsWith("prompt_")) {
        prompts += props.question.data[key] + "/";
      }
    }
    answers = prompts + " -- " + options;
  }

  // TODO keep in sync with QuestionCreateForm
  let typeNames = {
    multipleChoiceOne: getString("question_type_mcOne"),
    multipleChoiceMany: getString("question_type_mcMany"),
    scaleOne: getString("question_type_scaleOne"),
    textInput: getString("question_type_text"),
    numberInput: getString("question_type_number"),
  };

  return (
    <tr>
      <td>{typeNames[props.question.type]}</td>
      <td><QuestionTextDisplay text={props.question.text}/></td>
      <td>{answers}</td>
      <td><button onClick={() => props.deleteQuestion(props.question._id)}>{getString("button_delete")}</button></td>
    </tr>
  );
}

function QuestionList(props) {
  return (
    <table className="ListTable">
      <thead>
        <tr>
          <th>{getString("question_list_type")}</th>
          <th>{getString("question_list_text")}</th>
          <th>{getString("question_list_answers")}</th>
          <th>{getString("question_list_delete")}</th>
        </tr>
      </thead>
      <tbody>
        {props.survey.questions.map((question, index) =>
              <QuestionListItem
                key={question._id}
                index={index + 1}
                question={question}
                deleteQuestion={props.deleteQuestion}/>)}
      </tbody>
    </table>
  );
}

class TakeSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey: null,
      state: "starting" // starting | taking | done
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onQuestionAnswered = this.onQuestionAnswered.bind(this);
  }

  async componentDidMount() {
    let res = await getApi("surveysApi/loadSurvey", {
      surveyId: this.props.match.params.id,
    });
    let data = await res.json();
    if (data.survey) {
      this.setState({
        survey: data.survey,
      });
    } else {
      console.log(data.error);
    }
  }

  async onSubmit() {
    let responses = JSON.parse(JSON.stringify(this.state));
    delete responses.survey;
    delete responses.state;

    await postApi("surveysApi/submitResponse", {
      surveyId: this.state.survey._id,
      surveyVersion: this.state.survey.version,
      responses: responses,
    });

    this.setState({
      state: "done"
    });
  }

  onQuestionAnswered(questionId, answer) {
    let change = {};
    change[questionId] = answer;
    this.setState(change);
  }

  render() {
    if (!this.state.survey) {
      return (
        <p>{getString("generic_loading")}</p>
      );
    }

    if (this.state.state === "done") {
      return (
        <div>
          <h2>{this.state.survey.name}</h2>
          <p>{this.state.survey.finishedMessage}</p>
          <button onClick={() => this.props.history.push("/survey/surveys")}>{getString("button_done")}</button>
        </div>
      );
    }

    if (this.state.state === "starting") {
      return (
        <div>
          <h2>{this.state.survey.name}</h2>
          <p>{this.state.survey.welcomeMessage}</p>
          <button onClick={() => this.setState({state: "taking"})}>{getString("button_start")}</button>
        </div>
      );
    }

    // state === "taking"
    let questions = [];
    for (const idx in this.state.survey.questions) {
      let question = this.state.survey.questions[idx];
      if (question.type === "multipleChoiceOne") {
        questions.push(
          <QuestionMultipleChoiceOne
            key={idx}
            question={question}
            selectedValue={this.state[question._id] || ""}
            onQuestionAnswered={this.onQuestionAnswered}/>
        );
      } else if (question.type === "multipleChoiceMany") {
        questions.push(
          <QuestionMultipleChoiceMany
            key={idx}
            question={question}
            selectedValues={this.state[question._id] || {}}
            onQuestionAnswered={this.onQuestionAnswered}/>
        );
      } else if (question.type === "scaleOne") {
        questions.push(
          <QuestionScaleOne
            key={idx}
            question={question}
            selectedValues={this.state[question._id] || {}}
            onQuestionAnswered={this.onQuestionAnswered}/>
        );
      } else if (question.type === "textInput") {
        questions.push(
          <QuestionTextInput
            key={idx}
            question={question}
            currentValue={this.state[question._id] || ""}
            onQuestionAnswered={this.onQuestionAnswered}/>
        );
      } else if (question.type === "numberInput") {
        questions.push(
          <QuestionNumberInput
            key={idx}
            question={question}
            currentValue={this.state[question._id]}
            onQuestionAnswered={this.onQuestionAnswered}/>
        );
      }
    }

    const canSubmit = Object.keys(this.state.survey.questions).length === Object.keys(this.state).length - 2;
    return (
      <div className="TakeSurvey">
        <h2>{this.state.survey.name}</h2>
        {questions}
        <button
          className="SurveySubmitButton"
          onClick={this.onSubmit}
          disabled={!canSubmit}>{getString("button_submit")}</button>
      </div>
    );
  }
}

function SurveyPageHeader(props) {
  const getClassName = (button) => {
    if (button === "/surveys") {
      return button === props.location.pathname ? "Active" : "";
    }
    return props.location.pathname.startsWith(button) ? "Active" : "";
  }
  let isLoggedIn = props.user ? true : false;
  let showEdit = props.location.pathname.startsWith("/surveys/edit");
  let showTake = props.location.pathname.startsWith("/surveys/take");

  return (
    <div className="SurveyHeader">
      {showEdit && <Link to="/survey/surveys/edit" className={getClassName("/survey/surveys/edit")}>{getString("survey_page_edit")}</Link>}
      {showTake && <Link to="/survey/surveys/take" className={getClassName("/survey/surveys/take")}>{getString("survey_page_take")}</Link>}
      {isLoggedIn && <Link to="/survey/surveys/create" className={getClassName("/survey/surveys/create")}>{getString("survey_page_create")}</Link>}
      <Link to="/survey/surveys" className={getClassName("/survey/surveys")}>{getString("survey_page_list")}</Link>
    </div>
  );
}

function SurveyPage(props) {
  return (
    <div className="ContentDiv">
      <h1>Surveys</h1>
      <SurveyPageHeaderWR
        user={props.user}/>
      <Switch>
        <Route exact path="/survey/surveys">
          <SurveyList user={props.user}/>
        </Route>
        <Route path="/survey/surveys/create">
          <SurveyCreateForm onSuccess={(res) => props.history.push("/survey/surveys/edit/" + res.surveyId)}/>
        </Route>
        <Route path="/survey/surveys/take/:id">
          <TakeSurveyWR/>
        </Route>
        <Route path="/survey/surveys/edit/:id">
          <EditSurveyWR user={props.user}/>
        </Route>
      </Switch>
    </div>
  );
}

let SurveyPageWR = withRouter(SurveyPage);
let SurveyPageHeaderWR = withRouter(SurveyPageHeader);
let TakeSurveyWR = withRouter(TakeSurvey);
let EditSurveyWR = withRouter(EditSurvey);
export {SurveyPageWR};
