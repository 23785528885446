import React, {Component} from 'react';

import '../css/HomePage.css';
import {Link} from 'react-router-dom';

import quote_0 from '../assets/home/quote_0.png';
import quote_0_back from '../assets/home/gradient.png';

import home_header from '../assets/home/home_header.jpg';
import logo from '../assets/home/logo_cl.png';

import solutions_header from '../assets/home/solutions_header.png';
import client_header from '../assets/home/client_header.png';

import checkbox_img from '../assets/common/checkbox.png';

import market_title from '../assets/home/market_title.png';
import endo_title from '../assets/home/endo_title.png';
import estudios_title from '../assets/home/estudios_title.png';
import comu_title from '../assets/home/commu_title.png';

import market_icon from '../assets/home/market_icon.png';
import estudios_icon from '../assets/home/estudios_icon.png';
import endo_icon from '../assets/home/endo_icon.png';
import comu_icon from '../assets/home/comu_icon.png';

import client_0 from '../assets/home/client_0.png';
import client_1 from '../assets/home/client_1.png';
import client_2 from '../assets/home/client_2.png';
import client_3 from '../assets/home/client_3.png';
import client_4 from '../assets/home/client_4.png';
import client_5 from '../assets/home/client_5.png';
import client_6 from '../assets/home/client_6.png';
import client_7 from '../assets/home/client_7.png';
import client_8 from '../assets/home/client_8.png';
import client_9 from '../assets/home/client_9.png';

// Building Blocks
function SolutionBox(props) {
  return (
    <div className="SolutionBox">
      <div className={"SolutionIconHolder " + props.icon_style_prefix}>
        <div className="SolutionIcon">
          <img src={props.icon_img} className="IconImg" alt=""/>
        </div>
      </div>
      <div className="SolutionInfo">
        <img
            src={props.title_img}
            style={{maxHeight: "calc(10vh * " + props.hpx + " / 84 )"}}
            alt=""/>
        <div>
          <img className="Checkbox" src={checkbox_img} alt=""/>
          <p className="SolutionText">{props.text}</p>
        </div>
        <Link className="VerMas" to={props.link}>VER MÁS</Link>
      </div>
    </div>
  );
}

class QuoteBar1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteIndex: 0,
      lastIndex: 0,
    }
    this.quotes = [
      "Bola de Nieve, muy buena experiencia trabajar con ustedes! Gracias por su excelente servicio al cliente, por atender nuestras necesidades, por cada visita para revisión de proyecto, por su disposición, rapidez y calidad humana!",
      "Recomiendo altamente a la compañía Bola de Nieve, ya que ofrecen soluciones integrales, a toda la gama de servicios y productos que ofrecen, nos han ayudado mucho en la parte de campañas de seguridad, diseño de pizarrones para indicadores, encuestas de factores psicosociales, estudios de mercado, todo ello ofreciendo ante todo calidad, flexibilidad, creatividad, compromiso y respuesta inmediata.",
      "El tiempo que he trabajado con Bola de Nieve ha sido muy productivo, las ideas que se generan, el soporte, los diseños que desarrollan ayudan y facilitan el envió del mensaje a transmitir. La energía con que trabajan y la atención que brindan, el servicio al cliente son parte de sus valores como empresa, me gusta trabajar con ellos ya que siempre tienen la disponibilidad de ayudarte, te dan ideas y propuestas innovadoras y creativas y siempre están al pendiente de tus comentarios como cliente. Estoy muy contenta de trabajar con ellos y queremos continuar asi…"
    ];
    this.authors = [
      "Laura Pérez A.",
      "Armando Corpus",
      "Nayeli Zuñiga",
    ];
  }

  componentDidMount() {
    this.scrollInterval = setInterval(() => {
      const nextPage = (this.state.quoteIndex + 1) % this.quotes.length;
      this.setState({quoteIndex: nextPage, lastIndex: this.state.quoteIndex});
    }, 8000);
  }

  componentWillUnmount() {
    clearInterval(this.scrollInterval);
  }

  render() {
    function getClassForIndex(index, state, quotes) {
      let className = "QuoteView";

      // Only animate the current and last pages; allows moving all other pages silently
      // in the background at key steps
      if (index !== state.quoteIndex && index !== state.lastIndex) {
        className += " NoAnimation";
      }

      // Normal case - we just moved to a page greater than 0
      if (state.quoteIndex !== 0) {
        // When we reach the last page, silently hide the 0th page to the right
        // so on next transition it slides in from that direction
        if (state.quoteIndex === quotes.length - 1 && index === 0) {
          className += " HiddenRight";
        } else {
          if (index < state.quoteIndex) {
            className += " HiddenLeft";
          } else if (index > state.quoteIndex) {
            className += " HiddenRight";
          }
        }
      } else { // Looped back around case
        if (index === quotes.length - 1) {
          className += " HiddenLeft"
        } else {
          if (index < state.quoteIndex) {
            className += " HiddenLeft";
          } else if (index > state.quoteIndex) {
            className += " HiddenRight";
          }
        }
      }
      return className;
    }

    let quoteViews = this.quotes.map((quote, idx) =>
      <div className={getClassForIndex(idx, this.state, this.quotes)} key={idx}>
        <span>-"{quote}"</span>
        <br/>
        <span>-{this.authors[idx]}</span>
      </div>
    );
    return (
      <div className="QuoteBar1">
          {quoteViews}
      </div>
    );
  }
}

// Pages
function HomeHeader(props) {
  return (
    <div className="PageDiv" onClick={() => props.goTo("/")}>
      <img className="HeaderImg HomeHeaderImg" src={home_header} alt=""/>
      <img className="LogoImg" src={logo} alt=""/>
      <div className="HomeHeaderQuote">
        <img className="Quote0Bck" src={quote_0_back} style={{opacity: 0.5}} alt=""/>
        <img className="Quote0Img" src={quote_0} alt=""/>
      </div>
    </div>
  );
}

function Page1() {
  return (
    <div className="PageDiv HomePage">
      <div className="Title">
        <div className="CenterVert">
          <img className="InfoTextImg"  src={solutions_header} alt=""/>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="HomeBody CenterVert">
          <SolutionBox
            icon_img={market_icon}
            icon_style_prefix="Marketing"
            title_img={market_title}
            hpx="74"
            link="/marketing-externo"
            text={"Garantiza el éxito de tu empresa o proyecto comprendiendo de mejor forma a tu mercado potencial"}/>
        <SolutionBox
            icon_img={endo_icon}
            icon_style_prefix="Endo"
            title_img={endo_title}
            hpx="45"
            link="/endo-marketing"
            text={"Mejora el bienestar de los integrantes de la organización para aumentar su productividad y lealtad"}/>
        <br/>
        <SolutionBox
            icon_img={estudios_icon}
            icon_style_prefix="Estudios"
            title_img={estudios_title}
            hpx="84"
            link="/estudio"
            text={"Protege la salud emocional de los colaboradores de la organización y previene riesgos psicosociales de acuerdo a la NOM 035 de Secretaría del Trabajo y Previsión Social (STPS)"}/>
        <SolutionBox
            icon_img={comu_icon}
            icon_style_prefix="Comu"
            title_img={comu_title}
            hpx="74"
            link="/comunicacion"
            text={"Con nuestro servicio de diseño gráfico, fabricación de tableros y pizarrones e impresión de material publicitario, soluciona las necesidades de comunicación interna de tu organización"}/>
        </div>
      </div>
    </div>
  );
}

function Page1p1() {
  return (
    <div className="PageDiv HomePage">
      <div className="Title">
        <div className="CenterVert">
          <img src={solutions_header} alt="Soluciones para tu empreza"/><br/>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="HomeBody CenterVert">
          <SolutionBox
            icon_img={market_icon}
            icon_style_prefix="Marketing"
            title_img={market_title}
            hpx="74"
            link="/marketing-externo"
            text={"Garantiza el éxito de tu empresa o proyecto comprendiendo de mejor forma el panorama comercial"}/>
          <SolutionBox
            icon_img={endo_icon}
            icon_style_prefix="Endo"
            title_img={endo_title}
            hpx="45"
            link="/endo-marketing"
            text={"Mejora el bienestar de los integrantes de la organización para aumentar su productividad y lealtad"}/>
        </div>
      </div>
    </div>
  );
}

function Page1p2() {
  return (
    <div className="PageDiv HomePage">
      <div className="Title">
        <div className="CenterVert">
          <img src={solutions_header} alt="Soluciones para tu empreza"/><br/>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="HomeBody CenterVert">
          <SolutionBox
            icon_img={estudios_icon}
            icon_style_prefix="Estudios"
            title_img={estudios_title}
            hpx="84"
            link="/estudio"
            text={"Protege la salud emocional de los colaboradores de la organización y previene riesgos psicosociales de acuerdo a la NOM 035 de Secretaría del Trabajo y Previsión Social (STPS)"}/>
          <SolutionBox
            icon_img={comu_icon}
            icon_style_prefix="Comu"
            title_img={comu_title}
            hpx="74"
            link="/comunicacion"
            text={"Con nuestro servicio de diseño y fabricación de productos de comunicación visual, soluciona las necesidades de comunicación interna de tu organización"}/>
        </div>
      </div>
    </div>
  );

}

function Page2p1() {
  let logos_long = [
    client_0, client_1, client_2, client_3
  ];
  let logos_mid = [
    client_4, client_5, client_6,
  ];
  let logos_short = [
    client_7, client_8, client_9,
  ];
  return (
    <div className="PageDiv HomePage">
      <div className="Title">
        <div className="CenterVert">
          <img className="ClientHeader InfoTextImg" src={client_header} alt="Estas son algunas de las empresas..."/>
        </div>
      </div>
      <div className="CenterVert HomeBody">
        <div className="ClientList">
          {logos_long.map((logo, index) => <img src={logo} className="ClientLogoLong" key={index} alt=""/>)}
          <br/>
          {logos_mid.map((logo, index) => <img src={logo} className="ClientLogoMid" key={index} alt=""/>)}
          <br/>
          {logos_short.map((logo, index) => <img src={logo} className="ClientLogoShort" key={index} alt=""/>)}
        </div>
      </div>
    </div>
  )
}

function Page2p2() {
  return (
    <div className="PageDiv">
      <QuoteBar1 />
    </div>
  );
}

function Page2() {
  let logos_long = [
    client_0, client_1, client_2, client_3
  ];
  let logos_mid = [
    client_4, client_5, client_6,
  ];
  let logos_short = [
    client_7, client_8, client_9,
  ];
  return (
    <div className="PageDiv HomePage">
      <div className="Title">
        <div className="CenterVert">
          <img className="ClientHeader InfoTextImg" src={client_header} alt="Estas son algunas de las empresas..."/>
        </div>
      </div>
      <div className="CenterVert HomeBody">
        <div className="ClientList">
          {logos_long.map((logo, index) => <img src={logo} className="ClientLogoLong" key={index} alt=""/>)}
          <br/>
          {logos_mid.map((logo, index) => <img src={logo} className="ClientLogoMid" key={index} alt=""/>)}
          <br/>
          {logos_short.map((logo, index) => <img src={logo} className="ClientLogoShort" key={index} alt=""/>)}
        </div>
        <QuoteBar1 />
      </div>
    </div>
  )
}

let HomePageList = [
  <Page1 key="1"/>,
  <Page2 key="2"/>,
];

let HomePageListNarrow = [
  <Page1p1 key="1"/>,
  <Page1p2 key="2"/>,
  <Page2p1 key="3"/>,
  <Page2p2 key="4"/>,
];

export {
  HomeHeader,
  HomePageList,
  HomePageListNarrow,
};
