import React from 'react';

import '../css/EstudioPage.css';

import {MiddleSection} from "./Common";

import header from '../assets/estudio/header.jpg';
import header_text_0 from '../assets/estudio/header_text_0.png';
import header_text_1 from '../assets/estudio/header_text_1.png';
import la_nom_text from '../assets/estudio/la_nom_text.png';
import como_text from '../assets/common/como_text.png';
import img_0 from '../assets/estudio/img_0.jpg';
import img_1 from '../assets/estudio/img_1.jpg';
import img_2 from '../assets/estudio/img_2.jpg';

function EstudioHeader(props)  {
  return (
    <div className="PageDiv EstudioPage" onClick={() => props.goTo("/estudio")}>
      <img className="HeaderImg" src={header} alt=""/>
      <div className="HeaderTextImg">
        <img src={header_text_0} alt=""/>
        <img src={header_text_1} alt=""/>
      </div>
    </div>
  );
}

function Page1() {
  let items = [
    "Diagnóstico psicosocial",
  ];

  return (
    <div className="PageDiv EstudioPage">
      <div className="Title">
        <div className="CenterVert">
          <img className="InfoTextImg" src={la_nom_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
        <MiddleSection items={items} className="MiddleSectionEstudios"/>
        </div>
      </div>
    </div>
  );
}

function ComoTile(props) {
  return (
    <div className={"ComoTile Estudio"}>
      <img src={props.image} alt=""/>
      <div>
        <span>
          {props.text}
        </span>
      </div>
    </div>
  );
}

function ComoTileGroupFull()  {
  let items = [
    "INTERPRETACIÓN DE RESULTADOS QUE COLABOREN A LA IMPLEMENTACIÓN DE PLANES Y PROGRAMAS DE CUMPLIMIENTO NORMATIVO",
    "ELABORACIÓN DE CUESTIONARIO AD HOC PARA IDENTIFICACIÓN DE SIGNOS O SÍNTOMAS DE RIESGOS PSICOSOCIALES",
    "GESTIÓN DE APLICACIÓN DEL CUESTIONARIO AL PERSONAL DE LA EMPRESA EN LÁPIZ Y PAPEL O DE FORMA ELECTRÓNICA",
  ];

  let images = [
    img_0,
    img_1,
    img_2,
  ];

  return (
    <div className="ComoTileGroup Estudio">
      <ComoTile text={items[0]} image={images[0]}/>
      <ComoTile text={items[1]} image={images[1]}/>
      <br/>
      <ComoTile text={items[2]} image={images[2]}/>
    </div>
  );
}

function ComoTileGroupHalf1()  {
  let items = [
    "INTERPRETACIÓN DE RESULTADOS QUE COLABOREN A LA IMPLEMENTACIÓN DE PLANES Y PROGRAMAS DE CUMPLIMIENTO NORMATIVO.",
    "ELABORACIÓN DE CUESTIONARIO AD HOC PARA IDENTIFICACIÓN DE SIGNOS O SÍNTOMAS DE RIESGOS PSICOSOCIALES",
  ];

  let images = [
    img_0,
    img_1,
  ];

  return (
    <div className="ComoTileGroupHalf Estudio">
      <ComoTile text={items[0]} image={images[0]}/>
      <br/>
      <ComoTile text={items[1]} image={images[1]}/>
    </div>
  );
}

function ComoTileGroupHalf2()  {
  let items = [
    "GESTIÓN DE APLICACIÓN DEL CUESTIONARIO AL PERSONAL DE LA EMPRESA EN LÁPIZ Y PAPEL O DE FORMA ELECTRÓNICA",
  ];

  let images = [
    img_2,
  ];

  return (
    <div className="ComoTileGroupHalf Estudio">
      <ComoTile text={items[0]} image={images[0]}/>
    </div>
  );
}

function Page2() {
  return (
    <div className="InfoPageBody PageDiv EstudioPage">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <ComoTileGroupFull />
        </div>
      </div>
    </div>
  );
}

function Page2p1() {
  return (
    <div className="InfoPageBody PageDiv EstudioPage">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <ComoTileGroupHalf1 />
        </div>
      </div>
    </div>
  );
}

function Page2p2() {
  return (
    <div className="InfoPageBody PageDiv EstudioPage">
      <div className="Title">
        <div className="CenterVert">
          <img className="ComoTextImg" src={como_text} alt=""></img>
        </div>
      </div>
      <div className="BodyUnderTitle">
        <div className="CenterVert">
          <ComoTileGroupHalf2 />
        </div>
      </div>
    </div>
  );
}

const EstudioPageList = [
  <Page1 key="1"/>,
  <Page2 key="2"/>,
];

const EstudioPageListPortrait = [
  <Page1 key="1"/>,
  <Page2p1 key="2"/>,
  <Page2p2 key="2"/>,
];

export {
  EstudioHeader,
  EstudioPageList,
  EstudioPageListPortrait,
}
