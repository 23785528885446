import React from 'react';
import '../css/ComunicacionPage.css';

import header from '../assets/comunicacion/header.jpg';
import header_text from '../assets/comunicacion/header_text.png';
import somos_text from '../assets/comunicacion/somos_text.png';

import piz_text from '../assets/comunicacion/piz.png';
import piz_img from '../assets/comunicacion/piz_img.jpg';
import sen_text from '../assets/comunicacion/sen.png';
import sen_text_center from '../assets/comunicacion/sen_center.png';
import sen_img from '../assets/comunicacion/sen_img.jpg';
import otr_text from '../assets/comunicacion/otr.png';
import otr_img from '../assets/comunicacion/otr_img.jpg';

function PizSection() {
  const tipos = [
    "Planeadores de producción",
    "Pizarrones de comunicación",
    "Pizarrones de Kanban",
    "Tableros portahojas/tarjeteros",
  ];
  const materiales = [
    "Acrílico",
    "Pizarrón blanco",
    "Policarbonato sólido",
    "Acero inoxidable",
    "Estructuras de aluminio",
  ];

  return (
    <div className="ComuSection Piz">
      <div className="ComuSectionTitle">
        <img src={piz_text} alt=""/>
      </div>
      <div className="ComuSectionBullets">
        <strong>Tipos:</strong>
        <ul>
          {tipos.map((text, key) => <li key={key}>{text}</li>)}
        </ul>
        <strong>Materiales:</strong>
        <ul>
          {materiales.map((text, key) => <li key={key}>{text}</li>)}
        </ul>
      </div>
      <img className="ComuSectionImage" src={piz_img} alt=""/>
    </div>
  );
}

function SenSection(props) {
  const tipos = [
    "Señalética de seguridad",
    "Señalética corporativa",
    "Diseño e instalación",
  ];
  const materiales = [
    "Vinil impreso",
    "Corte de vinil",
  ];

  let image = (
    <img className="ComuSectionImage" src={sen_img} alt=""/>
  );
  let textImage = (
    <img src={sen_text} alt=""/>
  );
  if (props.vertical) {
    textImage = (
      <img src={sen_text_center} alt=""/>
    );
  }
  let title = (
    <div className="ComuSectionTitle">
      {textImage}
    </div>
  );
  let bullets = (
    <div className="ComuSectionBullets">
      <strong>Tipos:</strong>
      <ul>
        {tipos.map((text, key) => <li key={key}>{text}</li>)}
      </ul>
      <strong>Materiales:</strong>
      <ul>
        {materiales.map((text, key) => <li key={key}>{text}</li>)}
      </ul>
    </div>
  );

  if (props.vertical) {
    return (
      <div className={"ComuSection Sen"}>
        {title}
        {bullets}
        {image}
      </div>
    );
  } else {
    return (
      <div className={"ComuSection Sen"}>
        {image}
        {title}
        {bullets}
      </div>
    );
  }
}

function OtrSection() {
  const items = [
    "Letreros de producción",
    "Pendones",
    "Floorgraphics",
    "Letreros de seguridad",
  ];
  return (
    <div className={"ComuSection Otr"}>
      <div className="ComuSectionTitle">
        <img src={otr_text} alt=""/>
      </div>
      <div className="ComuSectionBullets">
        <ul>
          {items.map((text, key) => <li key={key}>{text}</li>)}
        </ul>
      </div>
      <img className="ComuSectionImage" src={otr_img} alt=""/>
    </div>
  );
}

function ComunicacionHeader(props) {
  return (
    <div className="PageDiv ComuPage" onClick={() => props.goTo("/comunicacion")}>
      <img className="HeaderImg" src={header} alt=""/>
      <img className="HeaderTextImg" src={header_text} alt=""/>
    </div>
  );
}

function Page1() {
  // Note -- SiteApp.js picks ComunicacionPageListPortrait on same condition, so
  // in that case Page2p1 and Page2p2 will include PizSection instead of this and
  // exclude SenSection
  let section = null;
  if (window.innerWidth < window.innerHeight) {
    section = <SenSection vertical />
  } else {
    section = <PizSection />
  }

  return (
    <div className="InfoPageBody ComuPage PageDiv">
        <div className="Title">
          <div className="CenterVert">
            <img className="InfoTextImg" src={somos_text} alt=""></img>
          </div>
        </div>
        <div className="BodyUnderTitle">
          <div className="CenterVert">
            {section}
          </div>
        </div>
    </div>
  );
};

function Page2() {
  return (
    <div className="InfoPageBody ComuPage PageDiv">
      <div className="CenterVert">
        <SenSection />
        <OtrSection />
      </div>
    </div>
  );
};

function Page2p1() {
  return (
    <div className="InfoPageBody ComuPage PageDiv">
      <div className="CenterVert">
        <PizSection vertical/>
      </div>
    </div>
  );
};

function Page2p2() {
  return (
    <div className="InfoPageBody ComuPage PageDiv">
      <div className="CenterVert">
        <OtrSection vertical/>
      </div>
    </div>
  );
};

const ComunicacionPageList = [
  <Page1 key="1" />,
  <Page2 key="2" />,
];

const ComunicacionPageListPortrait = [
  <Page1 key="1" />,
  <Page2p1 key="2" />,
  <Page2p2 key="3" />,
];

export {
  ComunicacionHeader,
  ComunicacionPageList,
  ComunicacionPageListPortrait,
}
